import { handleActions } from 'redux-actions';
import produce from 'immer';
import { loadProfile, setMiniActive, setMobileOpen } from './actions';
import { abelanaAuthorize, k12Authorize, logout } from '../Login/actions';
import appRoutes from './routes';
import { handleFailure, loadingStart } from '../../utils/immerHelpers';
import {
  updateBillingAddress,
  updateDefaultAllowedApps,
  updateMailingAddress,
  updateOnlinePresence,
  updatePassword,
  updatePaymentDetails,
  updatePersonalInfo,
  updateUsername,
} from '../Account/actions';
import { APP_TOKEN } from '../../constants';
import { updateAccountPostAddExperience } from '../CatalogItem/actions';

const updateAccount = produce((draft, { payload: { data } }) => {
  draft.loading = false;

  draft.address1 = data.address1;
  draft.address2 = data.address2;
  draft.address3 = data.address3;
  draft.bluejeans_login = data.bluejeans_login;
  draft.bluejeans_password = data.bluejeans_password;
  draft.delivery_address1 = data.delivery_address1;
  draft.delivery_address2 = data.delivery_address2;
  draft.delivery_address3 = data.delivery_address3;
  draft.email = data.email;
  draft.facebook = data.facebook;
  draft.first_name = data.first_name;
  draft.has_billing_address = data.has_billing_address;
  draft.instagram = data.instagram;
  draft.last_name = data.last_name;
  draft.payment_card = data.payment_card;
  draft.phone_number = data.phone_number;
  draft.twitter = data.twitter;
  draft.username = data.username;
  draft.vr_center_title = data.vr_center_title;
  draft.website = data.website;
  draft.allow_all_applications_for_new_device_connections =
    data.allow_all_applications_for_new_device_connections;
  draft.allowed_applications_for_new_device_connections =
    data.allowed_applications_for_new_device_connections;
});

const initialState = {
  address1: null,
  address2: null,
  address3: null,
  bluejeans_login: null,
  bluejeans_password: null,
  contract: {
    calculation_method: null,
    currency: null,
    experience_price: null,
    id: null,
    is_credit_card_required: null,
    per_hour: null,
    per_minute: null,
  },
  contract_type: null,
  delivery_address1: null,
  delivery_address2: null,
  delivery_address3: null,
  email: null,
  facebook: null,
  first_name: null,
  has_billing_address: null,
  instagram: null,
  last_name: null,
  payment: {
    start: null,
    end: null,
    secondsBilled: null,
    duration: null,
    warn: null,
  },
  payment_card: null,
  phone_number: null,
  twitter: null,
  user_id: null,
  username: null,
  vr_center_title: null,
  website: null,
  isShowBilling: null,
  allow_all_applications_for_new_device_connections: null,
  allowed_applications_for_new_device_connections: null,
  /**
   * Sidebar
   */
  mobileOpen: false,
  miniActive: false,
  /**
   * Utils
   */
  authToken: localStorage.getItem(APP_TOKEN),
  routes: appRoutes,
  loading: false,
  error: '',
  message: false,
};

const globalReducer = handleActions(
  {
    [loadProfile.REQUEST]: loadingStart,
    [loadProfile.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;

      draft.address1 = data.address1;
      draft.address2 = data.address2;
      draft.address3 = data.address3;
      draft.bluejeans_login = data.bluejeans_login;
      draft.bluejeans_password = data.bluejeans_password;
      draft.contract = data.contract;
      draft.contract_type = data.contract_type;
      draft.delivery_address1 = data.delivery_address1;
      draft.delivery_address2 = data.delivery_address2;
      draft.delivery_address3 = data.delivery_address3;
      draft.email = data.email;
      draft.facebook = data.facebook;
      draft.first_name = data.first_name;
      draft.has_billing_address = data.has_billing_address;
      draft.instagram = data.instagram;
      draft.last_name = data.last_name;
      draft.payment = data.payment;
      draft.payment_card = data.payment_card;
      draft.phone_number = data.phone_number;
      draft.twitter = data.twitter;
      draft.user_id = data.user_id;
      draft.username = data.username;
      draft.vr_center_title = data.vr_center_title;
      draft.website = data.website;
      draft.isShowBilling = data.isShowBilling;
      draft.allow_all_applications_for_new_device_connections =
        data.allow_all_applications_for_new_device_connections;
      draft.allowed_applications_for_new_device_connections =
        data.allowed_applications_for_new_device_connections;
    }),
    [loadProfile.FAILURE]: handleFailure,
    [logout.SUCCESS]: produce(() => ({
      ...initialState,
      // It's not enough to just return initialState, because authToken is in
      // closure and will be static if used with original object
      authToken: null,
    })),
    [abelanaAuthorize.SUCCESS]: produce((draft, { payload: { token } }) => {
      draft.authToken = token;
    }),
    [k12Authorize.SUCCESS]: produce((draft, { payload: { token } }) => {
      draft.authToken = token;
    }),
    [setMobileOpen.REQUEST]: produce((draft, { payload: { value } }) => {
      draft.mobileOpen = value;
    }),
    [setMiniActive.REQUEST]: produce((draft, { payload: { value } }) => {
      draft.miniActive = value;
    }),
    /**
     * Account
     */
    [updateUsername.SUCCESS]: updateAccount,
    [updatePersonalInfo.SUCCESS]: updateAccount,
    [updateMailingAddress.SUCCESS]: updateAccount,
    [updatePaymentDetails.SUCCESS]: updateAccount,
    [updateBillingAddress.SUCCESS]: updateAccount,
    [updatePassword.SUCCESS]: updateAccount,
    [updateOnlinePresence.SUCCESS]: updateAccount,
    [updateDefaultAllowedApps.SUCCESS]: produce(
      (draft, { payload: { data } }) => {
        draft.allow_all_applications_for_new_device_connections =
          data.allow_all_applications_for_new_device_connections;
        draft.allowed_applications_for_new_device_connections =
          data.allowed_applications_for_new_device_connections;
      },
    ),
    [updateAccountPostAddExperience.SUCCESS]: produce(
      (
        draft,
        {
          payload: {
            first_name,
            last_name,
            phone_number,
            vr_center_title,
            website,
          },
        },
      ) => {
        draft.first_name = first_name;
        draft.last_name = last_name;
        draft.phone_number = phone_number;
        draft.vr_center_title = vr_center_title;
        draft.website = website;
      },
    ),
  },
  initialState,
);

export default globalReducer;
