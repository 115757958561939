import { call, put, select, takeLatest } from 'redux-saga/effects';
import { updatePaymentDetails } from '../actions';
import {
  makeSelectEmail,
  makeSelectUsername,
  makeSelectUserProfileRequired,
} from '../../App/selectors';
import Api from '../../../utils/Api';

function* watchUpdatePaymentDetails() {
  yield takeLatest(updatePaymentDetails.REQUEST, requestUpdatePaymentDetails);
}

function* requestUpdatePaymentDetails({
  payload: { stripe, cardElement, resolve },
}) {
  try {
    const username = yield select(makeSelectUsername());
    const email = yield select(makeSelectEmail());

    const stripeResponse = yield stripe.createToken(cardElement, {
      name: username,
      email,
    });

    console.log('Stripe response: ', stripeResponse);

    const userProfileRequired = yield select(makeSelectUserProfileRequired());

    const { data } = yield call(Api.auth.userProfile, {
      ...userProfileRequired,
      stripe_token: stripeResponse.token.id,
      payment_card: `${stripeResponse.token.card.brand},${stripeResponse.token.card.last4}`,
    });

    console.log('requestUpdatePaymentDetails response: ', data);

    resolve();

    yield put(updatePaymentDetails.success(data));
  } catch (error) {
    resolve(error.response.data);
    yield put(updatePaymentDetails.failure(error.response.data));
  }
}

export default watchUpdatePaymentDetails;
