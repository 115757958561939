import DashboardIcon from '@material-ui/icons/Dashboard';
import AppsIcon from '@material-ui/icons/Apps';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import Login from '../Login/Loadable';
import TermsOfUse from '../TermOfUse/Loadable';
import Home from '../Home/Loadable';
import Account from '../Account/Loadable';
import Library from '../Library/Loadable';
import LibraryItem from '../LibraryItem/Loadable';
import Catalog from '../Catalog/Loadable';
import CatalogItem from '../CatalogItem/Loadable';
import Billing from '../Billing/Loadable';
import NotFound from '../NotFound/Loadable';
import {
  userIsAuthenticated,
  userIsNotAuthenticated,
} from '../../utils/authWrappers';

const appRoutes = [
  {
    id: 'home',
    path: '/',
    component: userIsAuthenticated(Home),
    exact: true,
    auth: true,
    sidebarTitle: 'Dashboard',
    sidebarPath: '/',
    sidebarIcon: DashboardIcon,
    breadcrumbsTitle: 'Home',
  },
  {
    id: 'catalog',
    path: '/catalog',
    component: userIsAuthenticated(Catalog),
    exact: true,
    auth: true,
    sidebarTitle: 'Catalog',
    sidebarPath: '/catalog',
    sidebarIcon: AppsIcon,
    breadcrumbsTitle: 'Catalog',
  },
  {
    id: 'catalogItem',
    path: '/catalog/:catalogItemID',
    component: userIsAuthenticated(CatalogItem),
    exact: true,
    auth: true,
    breadcrumbsTitle: 'Catalog Item',
  },
  {
    id: 'library',
    path: '/library',
    component: userIsAuthenticated(Library),
    exact: true,
    auth: true,
    sidebarTitle: 'My Library',
    sidebarPath: '/library',
    sidebarIcon: MenuBookIcon,
    breadcrumbsTitle: 'Library',
  },
  {
    id: 'libraryItem',
    path: '/library/:libraryItemID',
    component: userIsAuthenticated(LibraryItem),
    exact: true,
    auth: true,
    breadcrumbsTitle: 'Library Item',
  },
  {
    id: 'billing',
    path: '/billing',
    component: userIsAuthenticated(Billing),
    exact: true,
    auth: true,
    sidebarTitle: 'Billing',
    sidebarPath: '/billing',
    sidebarIcon: ReceiptIcon,
    breadcrumbsTitle: 'Billing',
  },
  {
    id: 'account',
    path: ['/account/:accountTab', '/account'],
    component: userIsAuthenticated(Account),
    exact: true,
    auth: true,
    sidebarTitle: 'Account',
    sidebarPath: '/account',
    sidebarIcon: SettingsIcon,
    breadcrumbsTitle: 'Account',
  },
  {
    id: 'tou',
    path: ['content/terms-of-use', 'content/terms-of-use.html'],
    component: TermsOfUse,
    exact: true,
    breadcrumbsTitle: 'Terms of Use',
  },
  {
    id: 'login',
    path: ['/login/:loginTab?', '/register', '/reset-password'],
    component: userIsNotAuthenticated(Login),
    auth: false,
    breadcrumbsTitle: 'Login',
  },
  {
    id: 'notFound',
    path: '*',
    component: NotFound,
    exact: true,
    breadcrumbsTitle: 'Not Found',
  },
];

export default appRoutes;
