import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Typography } from '@material-ui/core';
import footerStyle from './styles/footerStyle';
import { PRIVACY_POLICY_URL } from '../../constants';

function Footer({ ...props }) {
  const { isAuthenticated, classes, className, white, black, transparent } =
    props;
  return (
    <footer
      className={classNames({
        [classes.footer]: true,
        [classes.white]: white,
        [classes.black]: black,
        [classes.transparent]: transparent,
        [className]: className,
      })}
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography align="left">
            &copy; {new Date().getFullYear()}{' '}
            <a href="http://abelanavr.com/">abelanavr.com</a>. All Rights
            Reserved.
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3} alignItems="center">
            {!isAuthenticated && (
              <Grid item>
                <Typography
                  className={`float-right d-none d-sm-inline-block ${classes.textSecondary}`}
                  align="right"
                >
                  <a
                    href={PRIVACY_POLICY_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Typography>
              </Grid>
            )}
            <Grid item>
              <a
                className="float-right d-none d-sm-inline-block"
                href="mailto:dev@abelanavr.com"
              >
                <Typography align="right">Support</Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

Footer.propTypes = {
  isAuthenticated: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  white: PropTypes.bool,
  black: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
