import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import slideStyle from './styles/slideStyle';

const Slide = ({ classes, title, description, image, index, slidesCount }) => {
  const [progress, setProgress] = useState('25% 100%');
  useEffect(() => {
    setProgressBar();
  }, []);

  const ProgressBar = styled.div`
    width: 175px;
    height: 3px;
    border-radius: 6px;
    background-image: linear-gradient(to right, #ffffff, #ffffff);
    background-repeat: no-repeat;
    background-size: ${progress};
  `;

  const setProgressBar = () => {
    const calc = ((index + 1) / slidesCount) * 100;
    setProgress(`${calc}% 100%`);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
        <div className={classes.imageWrapper}>
          <img className={classes.image} src={image} alt={title} />
        </div>
      </div>
      <div className={classes.infoBar}>
        <Typography className={classes.slideNumber}>0{index + 1}</Typography>

        <ProgressBar />
      </div>
    </div>
  );
};

Slide.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  slidesCount: PropTypes.number.isRequired,
};
export default withStyles(slideStyle)(Slide);
