import { fork, all, take } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import watchUpdateUsername from './sagaWatchers/updateUsername';
import watchUpdatePaymentDetails from './sagaWatchers/updatePaymentDetails';
import watchUpdatePersonalInfo from './sagaWatchers/updatePersonalInfo';
import watchUpdateMailingAddress from './sagaWatchers/updateMailingAddress';
import watchUpdateBillingAddress from './sagaWatchers/updateBillingAddress';
import watchUpdatePassword from './sagaWatchers/updatePassword';
import watchUpdateOnlinePresence from './sagaWatchers/updateOnlinePresence';
import watchGetK12Data from './sagaWatchers/getK12Data';
import watchUpdateK12Application from './sagaWatchers/updateK12Application';
import watchRefreshK12Application from './sagaWatchers/refreshK12Application';
import watchDeleteK12Application from './sagaWatchers/deleteK12Application';
import watchAddK12Application from './sagaWatchers/addK12Application';
import watchAddK12Account from './sagaWatchers/addK12Account';
import watchDeleteK12Account from './sagaWatchers/deleteK12Account';
import watchGetBlueJeansData from './sagaWatchers/getBlueJeansData';
import watchUpdateBlueJeansAccount from './sagaWatchers/deleteBlueJeansAccount';
import {
  addK12Account,
  addK12Application,
  deleteK12Account,
  deleteK12Application,
  updateBillingAddress,
  updateK12Application,
  updateMailingAddress,
  updateOnlinePresence,
  updatePassword,
  updatePaymentDetails,
  updatePersonalInfo,
  updateUsername,
  updateBlueJeansAccount,
  deleteBlueJeansAccount,
} from './actions';
import watchDeleteBlueJeansAccount from './sagaWatchers/updateBlueJeansAccount';

function* watchUpdateK12ApplicationSuccess() {
  while (true) {
    yield take(updateK12Application.SUCCESS);

    toastr.success('Success', 'K-12 application was successfully updated.');
  }
}

function* watchAddK12ApplicationSuccess() {
  while (true) {
    yield take(addK12Application.SUCCESS);

    toastr.success('Success', 'K-12 application was successfully added.');
  }
}
function* watchDeleteK12ApplicationSuccess() {
  while (true) {
    yield take(deleteK12Application.SUCCESS);

    toastr.success('Success', 'K-12 application was successfully deleted.');
  }
}

function* watchAddK12AccountSuccess() {
  while (true) {
    yield take(addK12Account.SUCCESS);

    toastr.success('Success', 'K-12 account was successfully added.');
  }
}

function* watchDeleteK12AccountSuccess() {
  while (true) {
    yield take(deleteK12Account.SUCCESS);

    toastr.success('Success', 'K-12 account was successfully deleted.');
  }
}

function* watchUpdateUsernameSuccess() {
  while (true) {
    yield take(updateUsername.SUCCESS);

    toastr.success('Success', 'Username was successfully updated.');
  }
}

function* watchUpdatePersonalInfoSuccess() {
  while (true) {
    yield take(updatePersonalInfo.SUCCESS);

    toastr.success('Success', 'Personal info was successfully updated.');
  }
}

function* watchUpdateMailingAddressSuccess() {
  while (true) {
    yield take(updateMailingAddress.SUCCESS);

    toastr.success('Success', 'Mailing address was successfully updated.');
  }
}

function* watchUpdatePaymentDetailsSuccess() {
  while (true) {
    yield take(updatePaymentDetails.SUCCESS);

    toastr.success('Success', 'Payment details was successfully updated.');
  }
}

function* watchUpdateBillingAddressSuccess() {
  while (true) {
    yield take(updateBillingAddress.SUCCESS);

    toastr.success('Success', 'Billing address was successfully updated.');
  }
}

function* watchUpdatePasswordSuccess() {
  while (true) {
    yield take(updatePassword.SUCCESS);

    toastr.success('Success', 'Password was successfully updated.');
  }
}

function* watchUpdateOnlinePresenceSuccess() {
  while (true) {
    yield take(updateOnlinePresence.SUCCESS);

    toastr.success('Success', 'Online presence was successfully updated.');
  }
}

function* watchUpdateBlueJeansAccountSuccess() {
  while (true) {
    yield take(updateBlueJeansAccount.SUCCESS);

    toastr.success('Success', 'BlueJeans account was successfully updated.');
  }
}

function* watchDeleteBlueJeansAccountSuccess() {
  while (true) {
    yield take(deleteBlueJeansAccount.SUCCESS);

    toastr.success('Success', 'BlueJeans account was successfully deleted.');
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetK12Data),
    fork(watchUpdateK12Application),
    fork(watchRefreshK12Application),
    fork(watchAddK12Application),
    fork(watchDeleteK12Application),
    fork(watchAddK12Account),
    fork(watchDeleteK12Account),
    fork(watchUpdateUsername),
    fork(watchUpdatePersonalInfo),
    fork(watchUpdateMailingAddress),
    fork(watchUpdatePaymentDetails),
    fork(watchUpdateBillingAddress),
    fork(watchUpdatePassword),
    fork(watchUpdateOnlinePresence),
    fork(watchGetBlueJeansData),
    fork(watchUpdateBlueJeansAccount),
    fork(watchDeleteBlueJeansAccount),
    fork(watchUpdateK12ApplicationSuccess),
    fork(watchAddK12ApplicationSuccess),
    fork(watchDeleteK12ApplicationSuccess),
    fork(watchAddK12AccountSuccess),
    fork(watchDeleteK12AccountSuccess),
    fork(watchUpdateUsernameSuccess),
    fork(watchUpdatePersonalInfoSuccess),
    fork(watchUpdateMailingAddressSuccess),
    fork(watchUpdatePaymentDetailsSuccess),
    fork(watchUpdateBillingAddressSuccess),
    fork(watchUpdatePasswordSuccess),
    fork(watchUpdateOnlinePresenceSuccess),
    fork(watchUpdateBlueJeansAccountSuccess),
    fork(watchDeleteBlueJeansAccountSuccess),
  ]);
}
