import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteBlueJeansAccount } from '../actions';
import Api from '../../../utils/Api';

function* watchDeleteBlueJeansAccount() {
  yield takeLatest(
    deleteBlueJeansAccount.REQUEST,
    requestDeleteBlueJeansAccount,
  );
}

function* requestDeleteBlueJeansAccount({ payload: { resolve } }) {
  try {
    yield call(Api.blueJeans.account.delete);

    console.log('requestDeleteBlueJeansAccount call');

    resolve();

    yield put(deleteBlueJeansAccount.success());
  } catch (error) {
    resolve(error.response.data);
    yield put(deleteBlueJeansAccount.failure(error.response.data));
  }
}

export default watchDeleteBlueJeansAccount;
