import { createRoutine } from 'redux-saga-routines';

const getK12Data = createRoutine('ACCOUNT/GET_K12_DATA', {
  success: (data) => ({ data }),
});

const updateK12Application = createRoutine('ACCOUNT/UPDATE_K12_APPLICATION', {
  request: (values, resolve, accountID) => ({ values, resolve, accountID }),
  success: (data, accountID) => ({ data, accountID }),
});

const refreshK12Application = createRoutine('ACCOUNT/REFRESH_K12_APPLICATION', {
  request: (applicationID, accountID) => ({ applicationID, accountID }),
  success: (data, accountID) => ({ data, accountID }),
});

const addK12Application = createRoutine('ACCOUNT/ADD_K12_APPLICATION', {
  request: (values, resolve, accountID) => ({
    values,
    resolve,
    accountID,
  }),
  success: (data, accountID) => ({ data, accountID }),
});

const deleteK12Application = createRoutine('ACCOUNT/DELETE_K12_APPLICATION', {
  request: (applicationID, accountID) => ({ applicationID, accountID }),
  success: (applicationID, accountID) => ({ applicationID, accountID }),
});

const addK12Account = createRoutine('ACCOUNT/ADD_K12_ACCOUNT', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
  success: (data, accountID) => ({ data, accountID }),
});

const deleteK12Account = createRoutine('ACCOUNT/DELETE_K12_ACCOUNT', {
  request: (accountID) => ({ accountID }),
  success: (accountID) => ({ accountID }),
});

const updateUsername = createRoutine('ACCOUNT/UPDATE_USERNAME', {
  request: (values, resolve) => ({ values, resolve }),
  success: (data) => ({ data }),
});

const updatePersonalInfo = createRoutine('ACCOUNT/UPDATE_PERSONAL_INFO', {
  request: (values, resolve) => ({ values, resolve }),
  success: (data) => ({ data }),
});

const updateMailingAddress = createRoutine('ACCOUNT/UPDATE_MAILING_ADDRESS', {
  request: (values, resolve) => ({ values, resolve }),
  success: (data) => ({ data }),
});

const updatePaymentDetails = createRoutine('ACCOUNT/UPDATE_PAYMENT_DETAILS', {
  request: (stripe, cardElement, resolve) => ({ stripe, cardElement, resolve }),
  success: (data) => ({ data }),
});

const updateBillingAddress = createRoutine('ACCOUNT/UPDATE_BILLING_ADDRESS', {
  request: (values, resolve) => ({ values, resolve }),
  success: (data) => ({ data }),
});

const updatePassword = createRoutine('ACCOUNT/UPDATE_PASSWORD', {
  request: (values, resolve) => ({ values, resolve }),
  success: (data) => ({ data }),
});

const updateOnlinePresence = createRoutine('ACCOUNT/UPDATE_ONLINE_PRESENCE', {
  request: (values, resolve) => ({ values, resolve }),
  success: (data) => ({ data }),
});

const updateDefaultAllowedApps = createRoutine(
  'ACCOUNT/UPDATE_DEFAULT_ALLOWED_APPS',
  {
    success: (data) => ({ data }),
  },
);

const getBlueJeansData = createRoutine('ACCOUNT/GET_BLUEJEANS_DATA', {
  success: (data) => ({ data }),
});

const updateBlueJeansAccount = createRoutine(
  'ACCOUNT/UPDATE_BLUEJEANS_ACCOUNT',
  {
    request: (values, resolve) => ({ values, resolve }),
    success: (data) => ({ data }),
  },
);

const deleteBlueJeansAccount = createRoutine(
  'ACCOUNT/DELETE_BLUEJEANS_ACCOUNT',
  {
    request: (resolve) => ({ resolve }),
  },
);

export {
  getK12Data,
  updateK12Application,
  refreshK12Application,
  addK12Application,
  deleteK12Application,
  addK12Account,
  deleteK12Account,
  updateUsername,
  updatePersonalInfo,
  updateMailingAddress,
  updatePaymentDetails,
  updateBillingAddress,
  updatePassword,
  updateOnlinePresence,
  getBlueJeansData,
  updateBlueJeansAccount,
  deleteBlueJeansAccount,
  updateDefaultAllowedApps,
};
