import { call, put, takeLatest } from 'redux-saga/effects';
import { resetCheck } from '../actions';
import Api from '../../../utils/Api';

/* eslint-disable no-console */
function* watchResetCheck() {
  yield takeLatest(resetCheck.REQUEST, requestResetCheck);
}

function* requestResetCheck({ payload: { username, resolve } }) {
  try {
    const { data } = yield call(Api.auth.reset.check, {
      username,
    });

    resolve();
    console.log('requestResetCheck response: ', data);
    yield put(resetCheck.success());
  } catch (error) {
    resolve(error.response.data);
    yield put(resetCheck.failure(error.response.data));
  }
}
/* eslint-enable no-console */

export default watchResetCheck;
