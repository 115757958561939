import { handleActions } from 'redux-actions';
import produce from 'immer';
import { getDeviceList, getDeviceListSilence } from './actions';
import { loadingStart } from '../../utils/immerHelpers';

const initialState = {
  device_list: [],
  /**
   * Utils
   */
  loading: false,
  error: null,
};

const inlineData = (draft, data) => {
  draft.device_list = [];
  data.forEach((item) => {
    draft.device_list.push({
      id: item.id,
      status: item.status,
      standalone: item.standalone,
      role: item.role,
      safe_mode: item.safe_mode,
      allow_all_applications: item.allow_all_applications,
      allowed_applications: item.allowed_applications,
      device_id: item.device_info.device_id,
      device_name: item.device_info.device_name,
      account_name: item.device_info.account_name,
      battery_level: item.device_info.battery_level,
      network_level: item.device_info.network_level,
      active_application: item.device_info.active_application,
      active_application_title: item.device_info.active_application_title,
    });
  });
};

const homeReducer = handleActions(
  {
    // [loadProfile.REQUEST]: loadingStart,
    // [loadProfile.SUCCESS]: loadingEnd,
    // [loadProfile.FAILURE]: loadingEnd,
    [getDeviceListSilence.SUCCESS]: produce((draft, { payload: { data } }) => {
      inlineData(draft, data);
    }),
    [getDeviceList.REQUEST]: loadingStart,
    [getDeviceList.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;

      inlineData(draft, data);
    }),
  },
  initialState,
);

export default homeReducer;
