import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  AppBar,
  Toolbar,
  Hidden,
  Breadcrumbs,
  Fab,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectBreadcrumbs,
  makeSelectLocation,
  makeSelectMiniActive,
  makeSelectMobileOpen,
} from '../../containers/App/selectors';
import { setMiniActive, setMobileOpen } from '../../containers/App/actions';
import headerStyle from './styles/headerStyle';

// TODO: update breadcrumbs to work with dynamic routes (like show uuid or any other field on "/route/:id" type routes)
const Header = ({
  classes,
  children,
  miniActive,
  mobileOpen,
  location,
  breadcrumbs,
  ...props
}) => (
  <AppBar
    position="relative"
    color="transparent"
    elevation={0}
    className={classes.root}
  >
    <Toolbar variant="dense" className={classes.toolbar}>
      <Grid container spacing={4} alignItems="center">
        <Grid item>
          <Hidden smDown implementation="css">
            <Fab
              size="small"
              onClick={() => props.setMiniActive(!miniActive)}
              classes={{
                root: classes.fabRoot,
                sizeSmall: classes.fabSmall,
              }}
            >
              {miniActive ? (
                <ChevronRight fontSize="inherit" />
              ) : (
                <ChevronLeft fontSize="inherit" />
              )}
            </Fab>
          </Hidden>
          <Hidden mdUp implementation="css">
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={() => props.setMobileOpen(!mobileOpen)}
              classes={{
                root: classes.iconButtonRoot,
              }}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Grid>
        <Grid item>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
          >
            {Array.isArray(breadcrumbs) &&
              breadcrumbs.map((breadcrumb, index) =>
                index === breadcrumbs.length - 1 ? (
                  <Typography key={breadcrumb.pathname}>
                    {breadcrumb.content}
                  </Typography>
                ) : (
                  <Link to={breadcrumb.pathname} key={breadcrumb.pathname}>
                    {breadcrumb.content}
                  </Link>
                ),
              )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {children}
    </Toolbar>
  </AppBar>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object,
  location: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  miniActive: PropTypes.bool.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  setMobileOpen: PropTypes.func.isRequired,
  setMiniActive: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  breadcrumbs: makeSelectBreadcrumbs(),
  mobileOpen: makeSelectMobileOpen(),
  miniActive: makeSelectMiniActive(),
});

const mapDispatchToProps = (dispatch) => ({
  setMobileOpen: (value) => dispatch(setMobileOpen.request(value)),
  setMiniActive: (value) => dispatch(setMiniActive.request(value)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(headerStyle),
)(Header);
