import { handleActions } from 'redux-actions';
import produce from 'immer';
import { loadProfile } from '../App/actions';
import { addExperience, getCatalogItem, clearCatalogItem } from './actions';
import {
  handleFailure,
  loadingEnd,
  loadingStart,
} from '../../utils/immerHelpers';

const initialState = {
  application_type: null,
  available_on_htc_vive: null,
  available_on_oculus: null,
  available_on_wmr: null,
  available_on_htc_valve_index: null,
  description: null,
  environments: null,
  files: [],
  id: null,
  is_demo: null,
  logo: null,
  price: null,
  publication_date: null,
  publisher: {
    title: null,
    logo: null,
  },
  releases: [],
  short_title: null,
  title: null,
  video: null,
  is_already_owned: null,
  privo_coppa_url: null,
  privo_student_url: null,
  /**
   * Utils
   */
  loading: false,
  error: null,
};

const catalogItemReducer = handleActions(
  {
    [loadProfile.REQUEST]: loadingStart,
    [loadProfile.SUCCESS]: loadingEnd,
    [loadProfile.FAILURE]: loadingEnd,
    [getCatalogItem.REQUEST]: loadingStart,
    [getCatalogItem.SUCCESS]: produce((draft, { payload: { catalogItem } }) => {
      draft.loading = false;

      draft.application_type = catalogItem.application_type;
      draft.available_on_htc_vive = catalogItem.available_on_htc_vive;
      draft.available_on_oculus = catalogItem.available_on_oculus;
      draft.available_on_wmr = catalogItem.available_on_wmr;
      draft.available_on_htc_valve_index =
        catalogItem.available_on_htc_valve_index;
      draft.description = catalogItem.description;
      draft.environments = catalogItem.environments;
      draft.files = catalogItem.files;
      draft.id = catalogItem.id;
      draft.is_demo = catalogItem.is_demo;
      draft.logo = catalogItem.logo;
      draft.price = catalogItem.price;
      draft.publication_date = catalogItem.publication_date;
      draft.publisher = catalogItem.publisher;
      draft.releases = catalogItem.releases;
      draft.short_title = catalogItem.short_title;
      draft.title = catalogItem.title;
      draft.video = catalogItem.video;
      draft.is_already_owned = catalogItem.is_already_owned;
      draft.privo_coppa_url = catalogItem.privo_coppa_url;
      draft.privo_student_url = catalogItem.privo_student_url;
      draft.package_name = catalogItem.package_name;
    }),
    [getCatalogItem.FAILURE]: handleFailure,
    [addExperience.REQUEST]: loadingStart,
    [addExperience.SUCCESS]: loadingEnd,
    [addExperience.FAILURE]: handleFailure,
    [clearCatalogItem.SUCCESS]: produce(() => initialState),
  },
  initialState,
);

export default catalogItemReducer;
