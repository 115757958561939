import { call, put, takeLatest } from 'redux-saga/effects';
import { getApps } from '../actions';
import Api from '../../../utils/Api';

function* watchGetApps() {
  yield takeLatest(getApps.REQUEST, requestGetApps);
}

function* requestGetApps() {
  try {
    const { data } = yield call(Api.k12.appsList);

    console.log('requestGetApps', data);

    yield put(getApps.success(data));
  } catch (error) {
    yield put(getApps.failure(error.response.data));
  }
}

export default watchGetApps;
