import { call, put, takeLatest } from 'redux-saga/effects';
import { addK12Account } from '../actions';
import Api from '../../../utils/Api';

function* watchAddK12Account() {
  yield takeLatest(addK12Account.REQUEST, requestAddK12Account);
}

function* requestAddK12Account({ payload: { values, resolve } }) {
  try {
    const { data } = yield call(Api.k12.account.post, values);

    console.log('requestAddK12Account response: ', data);

    resolve();

    yield put(addK12Account.success(data));
  } catch (error) {
    resolve(error.response.data);
    yield put(addK12Account.failure(error.response.data));
  }
}

export default watchAddK12Account;
