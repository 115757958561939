import { put, take } from 'redux-saga/effects';
import { clearAppId } from '../actions';

function* watchClearAppId() {
  while (true) {
    yield take(clearAppId.REQUEST);

    yield put(clearAppId.success());
  }
}

export default watchClearAppId;
