import { call, put, takeLatest } from 'redux-saga/effects';
import { abelanaAuthorize } from '../actions';
import Api from '../../../utils/Api';

function* watchAbelanaAuthorize() {
  yield takeLatest(abelanaAuthorize.REQUEST, requestAbelanaAuthorize);
}

function* requestAbelanaAuthorize({ payload: { values, resolve } }) {
  try {
    const { data } = yield call(Api.auth.login.abelana, values);

    console.log('requestAbelanaAuthorize', data);

    resolve();

    yield put(abelanaAuthorize.success(data.key));
  } catch (error) {
    resolve(error.response.data);
    yield put(abelanaAuthorize.failure(error.response.data));
  }
}

export default watchAbelanaAuthorize;
