import { handleActions } from 'redux-actions';
import produce from 'immer';
import { DEFAULT_LOCALE } from '../../i18n';
import { changeLocale } from './actions';
import { loadingEnd, loadingStart } from '../../utils/immerHelpers';

const languageProviderReducer = handleActions(
  {
    [changeLocale.REQUEST]: loadingStart,
    [changeLocale.SUCCESS]: produce((draft, { payload: { locale } }) => {
      draft.locale = locale;
      draft.loading = false;
    }),
    [changeLocale.FAILURE]: loadingEnd,
  },
  {
    locale: DEFAULT_LOCALE,
  },
);

export default languageProviderReducer;
