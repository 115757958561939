import { call, put, takeLatest } from 'redux-saga/effects';
import { signup } from '../actions';
import Api from '../../../utils/Api';

function* watchSignup() {
  yield takeLatest(signup.REQUEST, requestSignup);
}

function* requestSignup({ payload: { values, resolve } }) {
  try {
    const { data } = yield call(Api.auth.register, values);

    console.log('requestSignup', data);

    resolve();

    yield put(signup.success());
  } catch (error) {
    resolve(error.response.data);
    yield put(signup.failure(error.response.data));
  }
}

export default watchSignup;
