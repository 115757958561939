import React from 'react';
import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';
import HomeRounded from '@material-ui/icons/HomeRounded';
import { accountTabs } from '../Account/routes';

/**
 * Root selectors
 */
const selectRoot = (state) => state.global;

const makeSelectAddress1 = () =>
  createSelector(selectRoot, (subState) => subState.address1);

const makeSelectAddress2 = () =>
  createSelector(selectRoot, (subState) => subState.address2);

const makeSelectAddress3 = () =>
  createSelector(selectRoot, (subState) => subState.address3);

const makeSelectContractType = () =>
  createSelector(selectRoot, (subState) => subState.contract_type);

const makeSelectDeliveryAddress1 = () =>
  createSelector(selectRoot, (subState) => subState.delivery_address1);

const makeSelectDeliveryAddress2 = () =>
  createSelector(selectRoot, (subState) => subState.delivery_address2);

const makeSelectDeliveryAddress3 = () =>
  createSelector(selectRoot, (subState) => subState.delivery_address3);

const makeSelectEmail = () =>
  createSelector(selectRoot, (subState) => subState.email);

const makeSelectFacebook = () =>
  createSelector(selectRoot, (subState) => subState.facebook);

const makeSelectFirstName = () =>
  createSelector(selectRoot, (subState) => subState.first_name);

const makeSelectHasBillingAddress = () =>
  createSelector(selectRoot, (subState) => subState.has_billing_address);

const makeSelectInstagram = () =>
  createSelector(selectRoot, (subState) => subState.instagram);

const makeSelectLastName = () =>
  createSelector(selectRoot, (subState) => subState.last_name);

const makeSelectPaymentCard = () =>
  createSelector(selectRoot, (subState) => subState.payment_card);

const makeSelectPhoneNumber = () =>
  createSelector(selectRoot, (subState) => subState.phone_number);

const makeSelectSchoolName = () =>
  createSelector(selectRoot, (subState) => subState.school_name);

const makeSelectTwitter = () =>
  createSelector(selectRoot, (subState) => subState.twitter);

const makeSelectUserID = () =>
  createSelector(selectRoot, (subState) => subState.user_id);

const makeSelectUsername = () =>
  createSelector(selectRoot, (subState) => subState.username);

const makeSelectVRCenterTitle = () =>
  createSelector(selectRoot, (subState) => subState.vr_center_title);

const makeSelectWebsite = () =>
  createSelector(selectRoot, (subState) => subState.website);

const makeSelectIsShowBilling = () =>
  createSelector(selectRoot, (subState) => subState.isShowBilling);

const makeSelectAllowAllApplicationForNewDevices = () =>
  createSelector(
    selectRoot,
    (subState) => subState.allow_all_applications_for_new_device_connections,
  );

const makeSelectAllowedApplicationsForNewDevices = () =>
  createSelector(
    selectRoot,
    (subState) => subState.allowed_applications_for_new_device_connections,
  );

const makeSelectUserProfileRequired = () =>
  createSelector(selectRoot, (subState) => ({
    address1: subState.address1,
    address2: subState.address2,
    address3: subState.address3,
    bluejeans_login: subState.bluejeans_login,
    bluejeans_password: subState.bluejeans_password,
    delivery_address1: subState.delivery_address1,
    delivery_address2: subState.delivery_address2,
    delivery_address3: subState.delivery_address3,
    email: subState.email,
    facebook: subState.facebook,
    first_name: subState.first_name,
    has_billing_address: subState.has_billing_address,
    instagram: subState.instagram,
    last_name: subState.last_name,
    phone_number: subState.phone_number,
    twitter: subState.twitter,
    vr_center_title: subState.vr_center_title,
    website: subState.website,
  }));

/**
 * Payment selectors
 */
const selectPayment = (state) => state.global.payment;

const makeSelectPaymentStart = () =>
  createSelector(selectPayment, (subState) => subState.start);

const makeSelectPaymentEnd = () =>
  createSelector(selectPayment, (subState) => subState.end);

const makeSelectPaymentSecondsBilled = () =>
  createSelector(selectPayment, (subState) => subState.secondsBilled);

const makeSelectPaymentDuration = () =>
  createSelector(selectPayment, (subState) => subState.duration);

const makeSelectPaymentWarn = () =>
  createSelector(selectPayment, (subState) => subState.warn);

/**
 * Contract selectors
 */
const selectContract = (state) => state.global.contract;

const makeSelectContractCalculationMethod = () =>
  createSelector(selectContract, (subState) => subState.calculation_method);

const makeSelectContractCurrency = () =>
  createSelector(selectContract, (subState) => subState.currency);

const makeSelectContractExperiencePrice = () =>
  createSelector(selectContract, (subState) => subState.experience_price);

const makeSelectContractID = () =>
  createSelector(selectContract, (subState) => subState.id);

const makeSelectContractIsCreditCardRequired = () =>
  createSelector(
    selectContract,
    (subState) => subState.is_credit_card_required,
  );

const makeSelectContractPerHour = () =>
  createSelector(selectContract, (subState) => subState.per_hour);

const makeSelectContractPerMinute = () =>
  createSelector(selectContract, (subState) => subState.per_minute);

/**
 * Router selectors
 */
const selectRouter = (state) => state.router;

const makeSelectSearch = () =>
  createSelector(selectRouter, (subState) => subState.location.search);

const makeSelectLocation = () =>
  createSelector(selectRouter, (subState) => subState.location);

const makeSelectBreadcrumbs = () =>
  createSelector(
    (state) => state,
    (subState) => {
      const breadcrumbs = [{ pathname: '/', content: <HomeRounded /> }];

      if (subState.router.location.pathname === '/') {
        subState.global.routes.some(({ path, breadcrumbsTitle }) => {
          const match = matchPath('/', { exact: true, path });

          if (match) {
            breadcrumbs.push({
              pathname: '/',
              content: breadcrumbsTitle,
            });

            return true;
          }
          return false;
        });

        return breadcrumbs;
      }
      subState.router.location.pathname
        .replace(/\/$/, '')
        .split('/')
        .reduce((previous, current) => {
          const pathSection = `${previous}/${current}`;

          [...accountTabs, ...subState.global.routes].some(
            ({ path, breadcrumbsTitle }) => {
              const match = matchPath(pathSection, { exact: true, path });

              if (match) {
                const obj = {
                  catalog:
                    subState.catalogItem.title && subState.catalogItem.title,
                  library:
                    subState.libraryItem.application.title &&
                    subState.libraryItem.application.title,
                };

                const name = obj[previous.replace('/', '')];

                if (name) {
                  breadcrumbs.push({
                    pathname: pathSection,
                    content: name,
                  });
                } else {
                  breadcrumbs.push({
                    pathname: pathSection,
                    content: breadcrumbsTitle,
                  });
                }
                return true;
              }
              return false;
            },
          );
          return pathSection;
        });
      return breadcrumbs;
    },
  );

/**
 * Auth selectors
 */
const makeSelectUserIsAuthenticated = () =>
  createSelector(selectRoot, (subState) => !!subState.authToken);

const makeSelectUserIsNotAuthenticated = () =>
  createSelector(selectRoot, (subState) => !subState.authToken);

/**
 * Utils
 */
const makeSelectMobileOpen = () =>
  createSelector(selectRoot, (subState) => subState.mobileOpen);

const makeSelectMiniActive = () =>
  createSelector(selectRoot, (subState) => subState.miniActive);

const makeSelectLoading = () =>
  createSelector(selectRoot, (subState) => subState.loading);

const makeSelectError = () =>
  createSelector(selectRoot, (subState) => subState.error);

const makeSelectMessage = () =>
  createSelector(selectRoot, (subState) => subState.message);

export {
  /**
   * Global
   */
  makeSelectAddress1,
  makeSelectAddress2,
  makeSelectAddress3,
  makeSelectContractType,
  makeSelectDeliveryAddress1,
  makeSelectDeliveryAddress2,
  makeSelectDeliveryAddress3,
  makeSelectEmail,
  makeSelectFacebook,
  makeSelectFirstName,
  makeSelectHasBillingAddress,
  makeSelectInstagram,
  makeSelectLastName,
  makeSelectPaymentCard,
  makeSelectPhoneNumber,
  makeSelectSchoolName,
  makeSelectTwitter,
  makeSelectUserID,
  makeSelectUsername,
  makeSelectVRCenterTitle,
  makeSelectWebsite,
  makeSelectIsShowBilling,
  makeSelectUserProfileRequired,
  makeSelectAllowAllApplicationForNewDevices,
  makeSelectAllowedApplicationsForNewDevices,
  /**
   * Payment
   */
  makeSelectPaymentStart,
  makeSelectPaymentEnd,
  makeSelectPaymentSecondsBilled,
  makeSelectPaymentDuration,
  makeSelectPaymentWarn,
  /**
   * Contract
   */
  makeSelectContractCalculationMethod,
  makeSelectContractCurrency,
  makeSelectContractExperiencePrice,
  makeSelectContractID,
  makeSelectContractIsCreditCardRequired,
  makeSelectContractPerHour,
  makeSelectContractPerMinute,
  /**
   * Utils
   */
  makeSelectMobileOpen,
  makeSelectMiniActive,
  makeSelectLoading,
  makeSelectError,
  makeSelectMessage,
  /**
   * Router
   */
  makeSelectSearch,
  makeSelectLocation,
  makeSelectBreadcrumbs,
  /**
   * Auth
   */
  makeSelectUserIsAuthenticated,
  makeSelectUserIsNotAuthenticated,
};
