import { handleActions } from 'redux-actions';
import produce from 'immer';
import { loadProfile } from '../App/actions';
import {
  getK12Data,
  updateK12Application,
  refreshK12Application,
  addK12Application,
  deleteK12Application,
  addK12Account,
  deleteK12Account,
  updateUsername,
  updatePersonalInfo,
  updateMailingAddress,
  updatePaymentDetails,
  updateBillingAddress,
  updatePassword,
  updateOnlinePresence,
  getBlueJeansData,
  updateBlueJeansAccount,
  deleteBlueJeansAccount,
} from './actions';
import {
  handleFailure,
  loadingEnd,
  loadingStart,
} from '../../utils/immerHelpers';

export const initialState = {
  k12: false,
  blueJeans: false,
  /**
   * Utils
   */
  loading: false,
  error: '',
};

const accountReducer = handleActions(
  {
    [loadProfile.REQUEST]: loadingStart,
    [loadProfile.SUCCESS]: loadingEnd,
    [loadProfile.FAILURE]: loadingEnd,
    [getK12Data.REQUEST]: loadingStart,
    [getK12Data.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;

      draft.k12 = data;
    }),
    [getK12Data.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [updateK12Application.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [updateK12Application.SUCCESS]: produce(
      (draft, { payload: { data, accountID } }) => {
        draft.loading = false;
        draft.k12 = draft.k12.map((account) => {
          if (account.id === accountID) {
            return {
              ...account,
              vil_applications: account.vil_applications.map((application) =>
                application.id === data.id ? data : application,
              ),
            };
          }
          return account;
        });
      },
    ),
    [updateK12Application.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [refreshK12Application.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [refreshK12Application.SUCCESS]: produce(
      (draft, { payload: { data, accountID } }) => {
        draft.loading = false;
        draft.k12 = draft.k12.map((account) => {
          if (account.id === accountID) {
            return {
              ...account,
              vil_applications: account.vil_applications.map((application) =>
                application.id === data.id ? data : application,
              ),
            };
          }
          return account;
        });
      },
    ),
    [refreshK12Application.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [addK12Application.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [addK12Application.SUCCESS]: produce(
      (draft, { payload: { data, accountID } }) => {
        draft.loading = false;
        draft.k12 = draft.k12.map((account) => {
          if (account.id === accountID) {
            return {
              ...account,
              vil_applications: [...account.vil_applications, data],
            };
          }
          return account;
        });
      },
    ),
    [addK12Application.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [deleteK12Application.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [deleteK12Application.SUCCESS]: produce(
      (draft, { payload: { applicationID, accountID } }) => {
        draft.loading = false;
        draft.k12 = draft.k12.map((account) => {
          if (account.id === accountID) {
            return {
              ...account,
              vil_applications: account.vil_applications.filter(
                (application) => application.id !== applicationID,
              ),
            };
          }
          return account;
        });
      },
    ),
    [deleteK12Application.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [addK12Account.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [addK12Account.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;
      draft.k12 = [...draft.k12, data];
    }),
    [addK12Account.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [deleteK12Account.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [deleteK12Account.SUCCESS]: produce((draft, { payload: { accountID } }) => {
      draft.loading = false;
      draft.k12 = draft.k12.filter((account) => account.id !== accountID);
    }),
    [deleteK12Account.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [getBlueJeansData.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;

      draft.blueJeans = data;
    }),
    [getBlueJeansData.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [updateBlueJeansAccount.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [updateBlueJeansAccount.SUCCESS]: produce(
      (draft, { payload: { data } }) => {
        draft.loading = false;
        draft.blueJeans = data;
      },
    ),
    [updateBlueJeansAccount.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [deleteBlueJeansAccount.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [deleteBlueJeansAccount.SUCCESS]: produce((draft) => {
      draft.loading = false;
      draft.blueJeans = false;
    }),
    [deleteBlueJeansAccount.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    [updateUsername.REQUEST]: loadingStart,
    [updateUsername.SUCCESS]: loadingEnd,
    [updateUsername.FAILURE]: handleFailure,
    [updatePersonalInfo.REQUEST]: loadingStart,
    [updatePersonalInfo.SUCCESS]: loadingEnd,
    [updatePersonalInfo.FAILURE]: handleFailure,
    [updateMailingAddress.REQUEST]: loadingStart,
    [updateMailingAddress.SUCCESS]: loadingEnd,
    [updateMailingAddress.FAILURE]: handleFailure,
    [updatePaymentDetails.REQUEST]: loadingStart,
    [updatePaymentDetails.SUCCESS]: loadingEnd,
    [updatePaymentDetails.FAILURE]: handleFailure,
    [updateBillingAddress.REQUEST]: loadingStart,
    [updateBillingAddress.SUCCESS]: loadingEnd,
    [updateBillingAddress.FAILURE]: handleFailure,
    [updatePassword.REQUEST]: loadingStart,
    [updatePassword.SUCCESS]: loadingEnd,
    [updatePassword.FAILURE]: handleFailure,
    [updateOnlinePresence.REQUEST]: loadingStart,
    [updateOnlinePresence.SUCCESS]: loadingEnd,
    [updateOnlinePresence.FAILURE]: handleFailure,
  },
  initialState,
);

export default accountReducer;
