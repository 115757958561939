import { createSelector } from 'reselect';

const selectRoot = (state) => state.login;

const makeSelectLoading = () =>
  createSelector(selectRoot, (subState) => subState.loading);

const makeSelectError = () =>
  createSelector(selectRoot, (subState) => subState.error);

const makeSelectWarning = () =>
  createSelector(selectRoot, (subState) => subState.warning);

const makeSelectAppId = () =>
  createSelector(selectRoot, (subState) => subState.appId);

const makeSelectApps = () =>
  createSelector(selectRoot, (subState) => subState.apps);

const makeSelectToken = () =>
  createSelector(selectRoot, (substate) => substate.token);

export {
  makeSelectLoading,
  makeSelectError,
  makeSelectAppId,
  makeSelectApps,
  makeSelectWarning,
  makeSelectToken,
};
