import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  abelanaAuthorize,
  checkAppId,
  k12Authorize,
  getApps,
  logout,
  signup,
  clearAppId,
  setWarning,
  checkResetToken,
  clearToken,
  resetCheck,
  resetPassword,
} from './actions';
import {
  handleFailure,
  loadingEnd,
  loadingStart,
} from '../../utils/immerHelpers';

export const initialState = {
  loading: false,
  error: null,
  warning: { isOpen: false, schoolName: '' },
  appId: null,
  apps: [],
  token: null,
};

const loginReducer = handleActions(
  {
    /**
     * Abelana Authorize
     */
    [abelanaAuthorize.REQUEST]: loadingStart,
    [abelanaAuthorize.SUCCESS]: loadingEnd,
    [abelanaAuthorize.FAILURE]: handleFailure,
    /**
     * K-12 Authorize
     */
    [k12Authorize.REQUEST]: loadingStart,
    [k12Authorize.SUCCESS]: loadingEnd,
    [k12Authorize.FAILURE]: handleFailure,
    /**
     * Signup
     */
    [signup.REQUEST]: loadingStart,
    [signup.SUCCESS]: loadingEnd,
    [signup.FAILURE]: handleFailure,
    /**
     * Logout
     */
    [logout.REQUEST]: loadingStart,
    [logout.SUCCESS]: loadingEnd,
    [logout.FAILURE]: handleFailure,
    /**
     * Check App Id
     */
    [checkAppId.REQUEST]: loadingStart,
    [checkAppId.SUCCESS]: produce((draft, { payload: { appId } }) => {
      draft.loading = false;
      draft.appId = appId;
    }),
    [checkAppId.FAILURE]: loadingEnd,
    /**
     * Get Apps
     */
    [getApps.REQUEST]: loadingStart,
    [getApps.SUCCESS]: produce((draft, { payload: { apps } }) => {
      draft.loading = false;
      draft.apps = apps;
    }),
    [getApps.FAILURE]: handleFailure,
    /**
     * Clear App Id
     */
    [clearAppId.SUCCESS]: produce((draft) => {
      draft.appId = null;
    }),
    /**
     * Clear App Id
     */
    [setWarning.SUCCESS]: produce((draft, { payload: { warning } }) => {
      draft.warning = warning;
    }),
    /**
     * Check token in match params GET
     */
    [checkResetToken.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [checkResetToken.SUCCESS]: produce((draft, { payload: { token } }) => {
      draft.token = token;
      draft.loading = false;
    }),
    [checkResetToken.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    /**
     * Reset availability check POST
     */
    [resetCheck.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [resetCheck.SUCCESS]: produce((draft) => {
      draft.loading = false;
    }),
    [resetCheck.FAILURE]: produce((draft, { payload: { error } }) => {
      draft.loading = false;
      draft.error = error;
    }),
    /**
     * Reset password send POST
     */
    [resetPassword.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [resetPassword.SUCCESS]: produce((draft) => {
      draft.loading = false;
    }),
    [resetPassword.FAILURE]: produce((draft, { payload: { error } }) => {
      draft.loading = false;
      draft.error = error;
    }),
    /**
     * Clear token
     */
    [clearToken.SUCCESS]: produce((draft) => {
      draft.token = null;
    }),
  },
  initialState,
);

export default loginReducer;
