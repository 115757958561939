import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

const primaryColor = '#067B70';
const secondaryColor = '#067B70';
// const secondaryColor = '#1676F3';
const spacing = 8;

const theme = (type) =>
  createMuiTheme({
    variables: {
      sidebarWidth: 30 * spacing,
      sidebarMiniWidth: 8 * spacing,
    },
    spacing,
    typography: {
      useNextVariants: true,
      fontFamily:
        type === 'dark'
          ? 'Montserrat'
          : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    palette: {
      type,
      tonalOffset: 0.2,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: primaryColor,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: secondaryColor,
        contrastText: '#fff',
      },
      error: {
        light: red.A200,
        main: red[500],
        dark: red.A700,
        contrastText: '#FEFEFF',
      },
      warning: {
        light: orange.A200,
        main: orange[500],
        dark: orange.A700,
        contrastText: '#1B1B1B',
      },
      success: {
        light: green.A200,
        main: green[500],
        dark: green.A700,
        contrastText: '#FEFEFF',
      },
      info: {
        light: lightBlue.A200,
        main: lightBlue[500],
        dark: lightBlue.A700,
        contrastText: '#FEFEFF',
      },
      inactive: {
        main: '#636363',
        contrastText: '#FEFEFF',
      },
    },
  });

export default theme;
