import { createRoutine } from 'redux-saga-routines';

const loadProfile = createRoutine('APP/LOAD_PROFILE', {
  success: (data) => ({ data }),
  failure: (error) => ({ error }),
});

const auth = createRoutine('APP/AUTH');

export const setMobileOpen = createRoutine('APP/SET_MOBILE_OPEN', {
  request: (value) => ({ value }),
});
export const setMiniActive = createRoutine('APP/SET_MINI_ACTIVE', {
  request: (value) => ({ value }),
});

export { loadProfile, auth };
