import { APP_API_ENDPOINT } from '../constants';
import request from './request';

export default class Api {
  static auth = {
    login: {
      abelana: (data) => request.post(`${APP_API_ENDPOINT}/auth/login/`, data),
      k12: (data) => request.post(`${APP_API_ENDPOINT}/auth/login/k12/`, data),
    },
    logout: () => request.post(`${APP_API_ENDPOINT}/auth/logout/`),
    register: (data) =>
      request.post(`${APP_API_ENDPOINT}/auth/register/`, data),
    reset: {
      check: (data) =>
        request.post(`${APP_API_ENDPOINT}/auth/reset-password/`, data),
      confirm: (data) =>
        request.post(`${APP_API_ENDPOINT}/auth/reset-password/confirm/`, data),
    },
    user: () => request.get(`${APP_API_ENDPOINT}/auth/user/`),
    userProfile: (data) =>
      request.post(`${APP_API_ENDPOINT}/auth/user-profile/`, data),
  };

  static invoices = {
    getAll: () => request.get(`${APP_API_ENDPOINT}/invoices/`),
  };

  static subscriptions = {
    toggleAutoRenewal: (data) =>
      request.post(
        `${APP_API_ENDPOINT}/subscriptions/toggle_auto_renewal/`,
        data,
      ),
    renewalInfo: (data) =>
      request.post(`${APP_API_ENDPOINT}/subscriptions/renewal/info/`, data),
    renewal: (data) =>
      request.post(`${APP_API_ENDPOINT}/subscriptions/renewal/`, data),
    buyInfo: (data) =>
      request.post(`${APP_API_ENDPOINT}/subscriptions/buy/info/`, data),
    buy: (data) => request.post(`${APP_API_ENDPOINT}/subscriptions/buy/`, data),
  };

  static keys = {
    put: (id, data) => request.put(`${APP_API_ENDPOINT}/keys/${id}/`, data),
  };

  static store = {
    getAll: () => request.get(`${APP_API_ENDPOINT}/store/`),
    get: (id) => request.get(`${APP_API_ENDPOINT}/store/${id}/`),
  };

  static experience = {
    getAll: () => request.get(`${APP_API_ENDPOINT}/experience/`),
    get: (id) => request.get(`${APP_API_ENDPOINT}/experience/${id}/`),
    post: (data) => request.post(`${APP_API_ENDPOINT}/experience/`, data),
    addKey: (id, data) =>
      request.post(`${APP_API_ENDPOINT}/experience/${id}/addkey/`, data),
  };

  static blueJeans = {
    account: {
      get: () => request.get(`${APP_API_ENDPOINT}/bj_linking/`),
      put: (data) => request.put(`${APP_API_ENDPOINT}/bj_linking/`, data),
      delete: () => request.delete(`${APP_API_ENDPOINT}/bj_linking/`),
    },
  };

  static k12 = {
    appsList: () => request.get(`${APP_API_ENDPOINT}/store/k12-apps-list/`),
    account: {
      getAll: () => request.get(`${APP_API_ENDPOINT}/k12_linking/`),
      post: (data) =>
        request.post(`${APP_API_ENDPOINT}/k12_linking/`, {
          email: data.email,
          application_id: data.application_id,
          educator_code: data.educator_code,
        }),
      delete: (id) => request.delete(`${APP_API_ENDPOINT}/k12_linking/${id}/`),
    },
    application: {
      post: (data) =>
        request.post(`${APP_API_ENDPOINT}/k12_linking/app/`, {
          vil_account_id: data.vil_account_id,
          application_id: data.application_id,
          educator_code: data.educator_code,
        }),
      put: (id, data) =>
        request.put(`${APP_API_ENDPOINT}/k12_linking/${id}/`, {
          educator_code: data.educator_code,
        }),
      refresh: (id) =>
        request.post(
          `${APP_API_ENDPOINT}/k12_linking/${id}/refresh_student_codes/`,
        ),
      delete: (id) =>
        request.delete(`${APP_API_ENDPOINT}/k12_linking/${id}/app/`),
    },
  };

  static deviceManagement = {
    getAll: () => request.get(`${APP_API_ENDPOINT}/device-management/`),

    shutdown: (data) =>
      request.post(`${APP_API_ENDPOINT}/device-management/shutdown/`, data),
    reboot: (data) =>
      request.post(`${APP_API_ENDPOINT}/device-management/reboot/`, data),
    launchApp: (data) =>
      request.post(`${APP_API_ENDPOINT}/device-management/launch-app/`, data),
    closeApp: (data) =>
      request.post(`${APP_API_ENDPOINT}/device-management/close-app/`, data),

    activate: (data) =>
      request.post(`${APP_API_ENDPOINT}/device-management/activate/`, data),
    remove: (data) =>
      request.post(`${APP_API_ENDPOINT}/device-management/remove/`, data),

    edit: (data) =>
      request.post(`${APP_API_ENDPOINT}/device-management/edit/`, data),
    editDefaultAppList: (data) =>
      request.post(
        `${APP_API_ENDPOINT}/device-management/edit-default-apps/`,
        data,
      ),
    editAppList: (data) =>
      request.post(`${APP_API_ENDPOINT}/device-management/edit-apps/`, data),
  };
}
