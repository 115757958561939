import { call, put, select, takeLatest } from 'redux-saga/effects';
import { updatePersonalInfo } from '../actions';
import { makeSelectUserProfileRequired } from '../../App/selectors';
import Api from '../../../utils/Api';

function* watchUpdatePersonalInfo() {
  yield takeLatest(updatePersonalInfo.REQUEST, requestUpdatePersonalInfo);
}

function* requestUpdatePersonalInfo({ payload: { values, resolve } }) {
  try {
    const userProfileRequired = yield select(makeSelectUserProfileRequired());

    const { data } = yield call(Api.auth.userProfile, {
      ...userProfileRequired,
      ...values,
    });

    console.log('requestUpdatePersonalInfo response: ', data);

    resolve();

    yield put(updatePersonalInfo.success(data));
  } catch (error) {
    resolve(error.response.data);
    yield put(updatePersonalInfo.failure(error.response.data));
  }
}

export default watchUpdatePersonalInfo;
