import { call, put, takeLatest } from 'redux-saga/effects';
import { logout } from '../actions';
import Api from '../../../utils/Api';

function* watchLogout() {
  yield takeLatest(logout.REQUEST, requestLogout);
}

function* requestLogout() {
  try {
    const { data } = yield call(Api.auth.logout);

    console.log('requestLogout', data);

    yield put(logout.success());
  } catch (error) {
    yield put(logout.failure(error.response.data));
  }
}

export default watchLogout;
