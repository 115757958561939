const headerStyle = (theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    zIndex: theme.zIndex.drawer + 2,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {},
  fabRoot: {
    backgroundColor: '#ffffff',
  },
  fabSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    minHeight: theme.spacing(3),
  },
  iconButtonRoot: {
    marginRight: theme.spacing(3),
  },
  breadcrumbs: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexGrow: 1,
  },
});

export default headerStyle;
