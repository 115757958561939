import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import dashboardWrapperStyle from './styles/dashboardStyle';
import Sidebar from '../../../../../components/Sidebar';
import Header from '../../../../../components/Header';
import Footer from '../../../../../components/Footer';
import { makeSelectLocation, makeSelectMiniActive } from '../../../selectors';
import appRoutes from '../../../routes';

class DashboardWrapper extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    miniActive: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.mainPanel = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
    }
  }

  render() {
    const { classes, children, miniActive } = this.props;

    return (
      <div className={classes.wrapper}>
        <Sidebar routes={appRoutes.filter((route) => route.sidebarTitle)} />
        <div
          className={classNames({
            [classes.mainPanel]: true,
            [miniActive ? classes.mainPanelClose : classes.mainPanelOpen]: true,
          })}
          ref={this.mainPanel}
        >
          <Header />
          <div className={classes.content}>{children}</div>
          <Footer white isAuthenticated />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  miniActive: makeSelectMiniActive(),
});

export default compose(
  connect(mapStateToProps),
  withStyles(dashboardWrapperStyle),
)(DashboardWrapper);
