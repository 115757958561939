import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, Route } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Drawer,
  List,
  Hidden,
  AppBar,
  Toolbar,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import sidebarStyle from './styles/sidebarStyle';
import {
  makeSelectLocation,
  makeSelectMiniActive,
  makeSelectMobileOpen,
} from '../../containers/App/selectors';
import { setMiniActive, setMobileOpen } from '../../containers/App/actions';
import Logo from '../../images/logo.png';
import LogoMini from '../../images/logo_mini.png';
import { logout } from '../../containers/Login/actions';
import { PRIVACY_POLICY_URL } from '../../constants';

// TODO: Fix active item highlight nested routes (e.g. catalog item)
class Sidebar extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    miniActive: PropTypes.bool.isRequired,
    mobileOpen: PropTypes.bool.isRequired,
    dispatchSetMobileOpen: PropTypes.func.isRequired,
    dispatchSetMiniActive: PropTypes.func.isRequired,
    dispatchPush: PropTypes.func.isRequired,
    dispatchLogout: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.mobileOpen) {
        this.props.dispatchSetMobileOpen(false);
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeFunction);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }

  resizeFunction = () => {
    if (window.innerWidth <= 960) {
      this.props.dispatchSetMobileOpen(false);
      this.props.dispatchSetMiniActive(false);
    }
  };

  render() {
    const {
      classes,
      routes,
      miniActive,
      mobileOpen,
      dispatchPush,
      dispatchLogout,
    } = this.props;

    const content = (
      <>
        <AppBar position="relative" elevation={0}>
          <Toolbar className={classes.toolbar} disableGutters variant="dense">
            <Link
              to="/"
              className={classNames(classes.link, {
                [classes.linkMini]: miniActive,
              })}
            >
              <img
                src={miniActive ? LogoMini : Logo}
                alt="LogoMini"
                className={classNames(classes.logo, {
                  [classes.logoMini]: miniActive,
                })}
              />
            </Link>
          </Toolbar>
        </AppBar>

        <List>
          {routes.map(
            ({ id, path, sidebarPath, sidebarIcon, sidebarTitle }) => (
              <Route path={path} key={id}>
                {({ match }) => (
                  <ListItem
                    classes={{
                      selected: classes.listItemSelected,
                    }}
                    selected={!!match && match.isExact}
                    button
                    onClick={() => dispatchPush(sidebarPath)}
                  >
                    <ListItemIcon>
                      <SvgIcon component={sidebarIcon} />
                    </ListItemIcon>
                    <ListItemText primary={sidebarTitle} />
                  </ListItem>
                )}
              </Route>
            ),
          )}
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <ListItemIcon>
                <SvgIcon component={InfoOutlinedIcon} />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" />
            </ListItem>
          </a>
          <ListItem button onClick={dispatchLogout}>
            <ListItemIcon>
              <SvgIcon component={ExitToAppIcon} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </>
    );

    return (
      <>
        <Hidden smDown implementation="css">
          <Drawer
            anchor="left"
            variant="permanent"
            open
            classes={{
              paper: classNames({
                [classes.drawer]: true,
                [miniActive ? classes.drawerClose : classes.drawerOpen]: true,
              }),
            }}
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden mdUp implementation="css">
          <Drawer
            anchor="left"
            variant="temporary"
            open={mobileOpen}
            classes={{
              paper: classNames({
                [classes.drawer]: true,
                [miniActive ? classes.drawerClose : classes.drawerOpen]: true,
              }),
            }}
            onClose={() => this.props.dispatchSetMobileOpen(!mobileOpen)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {content}
          </Drawer>
        </Hidden>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  mobileOpen: makeSelectMobileOpen(),
  miniActive: makeSelectMiniActive(),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetMobileOpen: (value) => dispatch(setMobileOpen.request(value)),
  dispatchSetMiniActive: (value) => dispatch(setMiniActive.request(value)),
  dispatchLogout: () => dispatch(logout.request()),
  dispatchPush: (url) => dispatch(push(url)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sidebarStyle),
)(Sidebar);
