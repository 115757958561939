import 'core-js/stable';
import Raven from 'raven-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import WebFont from 'webfontloader';
import history from './utils/history';
import { RAVEN_DSN } from './constants';
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import configureStore from './configureStore';
import { translationMessages } from './i18n';
import App from './containers/App';
import LanguageProvider from './containers/LanguageProvider';
import 'sanitize.css/sanitize.css';
import './styles/global.scss';

if (RAVEN_DSN && process.env.NODE_ENV === 'production') {
  Raven.config(RAVEN_DSN).install();
}

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

WebFont.load({
  google: {
    families: ['Montserrat:100,200,300,400,500,600,700,800,900'],
  },
});

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  module.hot.accept(['./i18n', './containers/App'], () => {
    // ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
