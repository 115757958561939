import { call, put, takeLatest, select } from 'redux-saga/effects';
import { k12Authorize } from '../actions';
import Api from '../../../utils/Api';
import { makeSelectWarning } from '../selectors';

function* watchK12Authorize() {
  yield takeLatest(k12Authorize.REQUEST, requestK12Authorize);
}

function* requestK12Authorize({
  payload: { email, educatorCode, applicationId, resolve },
}) {
  try {
    const warning = yield select(makeSelectWarning());

    const { data } = yield call(Api.auth.login.k12, {
      email,
      educator_code: educatorCode,
      application_id: applicationId,
      confirm_warning: warning.isOpen,
    });

    console.log('requestK12Authorize', data);

    resolve();

    yield put(k12Authorize.success(data.key));
  } catch (error) {
    resolve(error.response.data);
    yield put(k12Authorize.failure(error.response.data));
  }
}

export default watchK12Authorize;
