import { put, select, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
import { checkAppId, clearAppId } from '../actions';
import { makeSelectSearch } from '../../App/selectors';

function* watchCheckAppId() {
  yield takeLatest(checkAppId.REQUEST, requestCheckAppId);
}

function* requestCheckAppId() {
  yield put(clearAppId.request());

  const { app_id: appId } = queryString.parse(yield select(makeSelectSearch()));

  if (appId) {
    yield put(checkAppId.success(appId));
  } else {
    yield put(checkAppId.failure());
  }
}

export default watchCheckAppId;
