import backgroundImage from '../../../../../../images/login/vector.png';

const containerStyles = (theme) => ({
  width: '100%',
  maxWidth: '1260px',
  margin: '0 auto',
  padding: theme.spacing(0, 2),
});

const loginWrapperStyle = (theme) => ({
  root: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#232327',
    overflowX: 'hidden',
    position: 'relative',
  },
  rootBackground: {
    position: 'absolute',
    left: '0',
    right: '22%',
    top: '0',
    bottom: '0',
    background: `left 0% / 100% 100% no-repeat url("${backgroundImage}")`,
  },
  header: {
    width: '100%',
    background: '#272B34',
    boxShadow: '0px 7px 15px rgba(0, 0, 0, 0.25)',
    zIndex: 1,
  },
  headerContainer: {
    ...containerStyles(theme),
    display: 'flex',
    alignItems: 'center',
  },
  headerLogo: {
    padding: '15px 0',
    height: '80px',
  },
  content: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  footerRoot: {
    width: '100%',
    background: '#272B34',
    boxShadow: '0px 7px 15px rgba(0, 0, 0, 0.25)',
    zIndex: 1,
  },
  footerContainer: {
    ...containerStyles(theme),
  },
  footer: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
});

export default loginWrapperStyle;
