import { call, put, takeLatest } from 'redux-saga/effects';
import { loadProfile } from '../actions';
import { CONTRACT_TYPES } from '../../../constants';
import Api from '../../../utils/Api';

function* watchLoadProfile() {
  yield takeLatest(loadProfile.REQUEST, requestLoadProfile);
}

function* requestLoadProfile() {
  try {
    const { data } = yield call(Api.auth.user);

    console.log('requestLoadProfile response: ', data);

    data.payment = data.payment || {};

    /**
     * Seconds Billed
     */
    const paymentSecondsBilled = data.payment.seconds_billed || 0;

    /**
     * Duration
     */
    const days = Math.floor(paymentSecondsBilled / 86400);
    const hours = Math.floor((paymentSecondsBilled - days * 86400) / 3600);
    const minutes = Math.floor(
      (paymentSecondsBilled - days * 86400 - hours * 3600) / 60,
    );
    const paymentDuration = `${days}d ${hours}h ${minutes}m`;

    /**
     * Start
     */
    const start = data.payment.start_at
      ? new Date(data.payment.start_at)
      : new Date();
    const paymentStart = `${start.toLocaleString('en-us', {
      month: 'long',
    })} ${start.getFullYear()}`;

    /**
     * End
     */
    let paymentEnd = null;

    if (data.payment.start_at) {
      const end = new Date(
        Math.max(+new Date(data.payment.end_at), +new Date()),
      );
      if (
        start.getFullYear() !== end.getFullYear() ||
        start.getMonth() !== end.getMonth()
      ) {
        paymentEnd = `${end.toLocaleString('en-us', {
          month: 'long',
        })} ${end.getFullYear()}`;
      }
    }

    yield put(
      loadProfile.success({
        ...data,
        isShowBilling:
          data.contract_type !== CONTRACT_TYPES.CONTRACT1 &&
          data.contract_type !== CONTRACT_TYPES.CONTRACT2,
        payment: {
          secondsBilled: paymentSecondsBilled,
          duration: paymentDuration,
          start: paymentStart,
          end: paymentEnd,
          warn: data.payment.warn,
        },
      }),
    );
  } catch (error) {
    yield put(loadProfile.failure(error.response.data));
  }
}

export default watchLoadProfile;
