import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router';
import ReduxToastr from 'react-redux-toastr';
import { ThemeProvider } from '@material-ui/core/styles';
import 'react-redux-toastr/src/styles/index.scss';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import appRoutes from '../routes';
import theme from '../../../styles/theme';
import AppWrapper from './components/AppWrapper';

const appTheme = theme('light');

class App extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    onInitialLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { location, onInitialLoad } = this.props;

    if (
      appRoutes.find(
        ({ path, exact, auth }) =>
          auth &&
          matchPath(location.pathname, {
            path,
            exact,
          }),
      )
    ) {
      onInitialLoad();
    }
  }

  render() {
    const { isAuthenticated } = this.props;

    return (
      <ThemeProvider theme={appTheme}>
        <Helmet
          titleTemplate="%s - VR 4 edu"
          defaultTitle="The Abelana Platform"
        >
          <meta name="description" content="VR application" />
        </Helmet>
        <AppWrapper isAuthenticated={isAuthenticated}>
          <Switch>
            {appRoutes.map(({ path, exact, component }) => (
              <Route
                key={path}
                path={path}
                exact={exact}
                component={component}
              />
            ))}
          </Switch>
        </AppWrapper>
        <ReduxToastr
          timeOut={4000}
          getState={(state) => state.toastr}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </ThemeProvider>
    );
  }
}

export default App;
