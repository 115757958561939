import React from 'react';
import PropTypes from 'prop-types';
import DashboardWrapper from '../DashboardWrapper';
import LoginWrapper from '../LoginWrapper';

const AppWrapper = ({ isAuthenticated, children }) =>
  isAuthenticated ? (
    <DashboardWrapper>{children}</DashboardWrapper>
  ) : (
    <LoginWrapper>{children}</LoginWrapper>
  );

AppWrapper.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default AppWrapper;
