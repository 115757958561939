import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Hidden } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Footer from '../../../../../components/Footer';
import theme from '../../../../../styles/theme';
import logo from '../../../../../images/logo.png';
import ContentSlider from './components/ContentSlider';
import loginWrapperStyle from './styles/loginWrapperStyle';

const loginTheme = theme('dark');

const Header = ({ classes }) => (
  <div className={classes.header}>
    <div className={classes.headerContainer}>
      <img className={classes.headerLogo} src={logo} alt="logo" />
    </div>
  </div>
);

class LoginWrapper extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  render() {
    const { classes, children } = this.props;
    return (
      <ThemeProvider theme={loginTheme}>
        <div className={classes.root}>
          <Header classes={classes} />
          <Hidden mdDown>
            <div className={classes.rootBackground} />
          </Hidden>
          <div className={classes.content}>
            <Grid container spacing={6} alignItems="center">
              <Hidden mdDown>
                <Grid item xs={12} lg={8}>
                  <ContentSlider />
                </Grid>
              </Hidden>
              <Grid item xs={12} lg={4}>
                <Box paddingY={3}>{children}</Box>
              </Grid>
            </Grid>
          </div>
          <div className={classes.footerRoot}>
            <div className={classes.footerContainer}>
              <Footer transparent className={classes.footer} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(loginWrapperStyle)(LoginWrapper);
