import { call, put, select, takeLatest } from 'redux-saga/effects';
import { updateMailingAddress } from '../actions';
import { makeSelectUserProfileRequired } from '../../App/selectors';
import Api from '../../../utils/Api';

function* watchUpdateMailingAddress() {
  yield takeLatest(updateMailingAddress.REQUEST, requestUpdateMailingAddress);
}

function* requestUpdateMailingAddress({ payload: { values, resolve } }) {
  try {
    const userProfileRequired = yield select(makeSelectUserProfileRequired());

    const { data } = yield call(Api.auth.userProfile, {
      ...userProfileRequired,
      ...values,
    });

    console.log('requestUpdateMailingAddress response: ', data);

    resolve();

    yield put(updateMailingAddress.success(data));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateMailingAddress.failure(error.response.data));
  }
}

export default watchUpdateMailingAddress;
