import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteK12Account } from '../actions';
import Api from '../../../utils/Api';

function* watchDeleteK12Account() {
  yield takeLatest(deleteK12Account.REQUEST, requestDeleteK12Account);
}

function* requestDeleteK12Account({ payload: { accountID } }) {
  try {
    const { data } = yield call(Api.k12.account.delete, accountID);

    console.log('requestDeleteK12Account response: ', data);

    yield put(deleteK12Account.success(accountID));
  } catch (error) {
    yield put(deleteK12Account.failure(error.response.data));
  }
}

export default watchDeleteK12Account;
