import { createRoutine } from 'redux-saga-routines';

const getDeviceList = createRoutine('HOME/GET_DEVICE_LIST', {
  request: () => ({}),
  success: (data) => ({ data }),
  failure: (error) => ({ error }),
});

const getDeviceListSilence = createRoutine('HOME/GET_DEVICE_LIST_SILENCE', {
  request: () => ({}),
  success: (data) => ({ data }),
  failure: (error) => ({ error }),
});

const sendShutdownCommand = createRoutine('HOME/SHUTDOWN_DEVICE', {
  request: (selectedItems) => ({ selectedItems }),
});

const sendRebootCommand = createRoutine('HOME/REBOOT_DEVICE', {
  request: (selectedItems) => ({ selectedItems }),
});

const sendLaunchCommand = createRoutine('HOME/LAUNCH_APP_ON_DEVICE', {
  request: (selectedItems, applicationId) => ({ selectedItems, applicationId }),
});

const sendCloseCommand = createRoutine('HOME/CLOSE_APP_ON_DEVICE', {
  request: (selectedItems, applicationId) => ({ selectedItems, applicationId }),
});

const activateDevice = createRoutine('HOME/ACTIVATE_DEVICE', {
  request: (selectedItems) => ({ selectedItems }),
});

const removeDevice = createRoutine('HOME/REMOVE_DEVICE', {
  request: (selectedItems) => ({ selectedItems }),
});

const editDevice = createRoutine('HOME/EDIT_DEVICE', {
  request: (selectedItem, accountName, role, safeMode, resolve) => ({
    selectedItem,
    accountName,
    role,
    safeMode,
    resolve,
  }),
});

const editDefaultAppList = createRoutine('HOME/EDIT_DEFAULT_APP_LIST', {
  request: (selectedItems) => ({
    selectedItems,
  }),
  success: (data) => ({ data }),
});

const editAppList = createRoutine('HOME/EDIT_APP_LIST', {
  request: (selectedItems, item) => ({
    selectedItems,
    item,
  }),
});

export {
  getDeviceList,
  getDeviceListSilence,
  sendShutdownCommand,
  sendRebootCommand,
  sendLaunchCommand,
  sendCloseCommand,
  activateDevice,
  removeDevice,
  editDevice,
  editDefaultAppList,
  editAppList,
};
