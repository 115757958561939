import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import loadingIndicatorStyle from './styles/loadingIndicatorStyle';

const LoadingIndicator = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <div className={classes.loadingIndicator}>
        <div
          className={classNames(
            classes.loadingIndicatorElectron,
            classes.loadingIndicatorX,
          )}
        />
        <div
          className={classNames(
            classes.loadingIndicatorElectron,
            classes.loadingIndicatorY,
          )}
        />
      </div>
    </div>
  </div>
);

export default withStyles(loadingIndicatorStyle)(LoadingIndicator);
