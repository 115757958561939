import { take, fork, put, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { APP_TOKEN } from '../../constants';
import { loadProfile } from '../App/actions';
import watchAbelanaAuthorize from './sagaWatchers/abelanaAuthorize';
import {
  abelanaAuthorize,
  k12Authorize,
  logout,
  setWarning,
  signup,
  resetCheck,
  resetPassword,
} from './actions';
import watchLogout from './sagaWatchers/logout';
import watchK12Authorize from './sagaWatchers/k12Authorize';
import watchCheckAppId from './sagaWatchers/checkAppId';
import watchGetApps from './sagaWatchers/getApps';
import watchSignup from './sagaWatchers/signup';
import watchClearAppId from './sagaWatchers/clearAppId';
import watchCheckResetToken from './sagaWatchers/checkResetToken';
import watchResetCheck from './sagaWatchers/resetCheck';
import watchResetPassword from './sagaWatchers/resetPassword';
import watchClearToken from './sagaWatchers/clearToken';

function* watchAbelanaAuthorizeSuccess() {
  while (true) {
    const {
      payload: { token },
    } = yield take(abelanaAuthorize.SUCCESS);

    localStorage.setItem(APP_TOKEN, token);

    yield put(loadProfile.request());

    yield put(push('/'));
  }
}

function* watchK12AuthorizeSuccess() {
  while (true) {
    const {
      payload: { token },
    } = yield take(k12Authorize.SUCCESS);

    localStorage.setItem(APP_TOKEN, token);

    yield put(loadProfile.request());

    yield put(push('/'));
  }
}

function* watchK12AuthorizeFailure() {
  while (true) {
    const {
      payload: { error },
    } = yield take(k12Authorize.FAILURE);

    if (
      typeof error === 'object' &&
      error !== null &&
      error.code === 'school_account_exists'
    ) {
      const warning = {
        isOpen: true,
        schoolName:
          error.data && error.data.school_name ? error.data.school_name : '',
      };
      yield put(setWarning.success(warning));
    }
  }
}

function* watchSignupSuccess() {
  while (true) {
    yield take(signup.SUCCESS);

    toastr.success(
      'Registration',
      'Please check you mail. You will be able to login once email address is confirmed',
    );

    yield put(push('/login'));
  }
}

function* watchLogoutSuccess() {
  while (true) {
    yield take(logout.SUCCESS);

    localStorage.removeItem(APP_TOKEN);

    // dirty fix to resolve issues with store and unmounting of component
    window.location.reload();
    // yield put(push('/login'));
  }
}

function* watchResetPasswordSuccess() {
  while (true) {
    yield take(resetPassword.SUCCESS);

    toastr.success(
      'Password has been changed',
      'You can now login with updated credentials.',
    );

    yield put(push('/login'));
  }
}

function* watchResetCheckSuccess() {
  while (true) {
    yield take(resetCheck.SUCCESS);

    toastr.success(
      'Email has been sent',
      'Check email to continue password reset.',
    );

    yield put(push('/login'));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAbelanaAuthorize),
    fork(watchAbelanaAuthorizeSuccess),
    fork(watchK12Authorize),
    fork(watchK12AuthorizeSuccess),
    fork(watchK12AuthorizeFailure),
    fork(watchLogout),
    fork(watchLogoutSuccess),
    fork(watchCheckAppId),
    fork(watchGetApps),
    fork(watchSignup),
    fork(watchSignupSuccess),
    fork(watchClearAppId),
    fork(watchCheckResetToken),
    fork(watchResetCheck),
    fork(watchResetPassword),
    fork(watchClearToken),
    fork(watchResetPasswordSuccess),
    fork(watchResetCheckSuccess),
  ]);
}
