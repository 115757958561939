const contentSliderStyle = (theme) => ({
  wrapper: {
    width: theme.spacing(100),
    padding: theme.spacing(25, 0, 2, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    paddingTop: '30px',
    paddingLeft: '53px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  progressBar: {
    width: '175px',
    height: '3px',
    marginRight: '70px',
    background: 'rgba(255, 255, 255, 0.35)',
    borderRadius: '6px',
  },
  leftArrowIcon: {
    color: 'transparent',
    fontSize: '42px',
  },
  rightArrowIcon: {
    color: 'transparent',
    fontSize: '42px',
    transform: 'rotate(180deg)',
  },
  buttonLeft: {
    padding: '0',
    borderRadius: '50%',
    minWidth: 'auto',
    marginRight: '24px',
  },
  buttonRight: {
    padding: '0',
    borderRadius: '50%',
    minWidth: 'auto',
  },
});

export default contentSliderStyle;
