const loadingIndicatorStyle = (theme) => ({
  '@keyframes rotating': {
    from: { WebkitTransform: 'rotate(0deg)' },
    to: { WebkitTransform: 'rotate(360deg)' },
  },
  root: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1000,
  },
  container: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
  },
  loadingIndicator: {
    display: 'inline-block',
    width: '50px',
    height: '50px',
    borderRadius: '50px',
    position: 'relative',
    margin: '10px',
    fontSize: '25px',
    lineHeight: '50px',
    textAlign: 'center',
    animation: `$rotating 2s linear infinite`,
    '&:before': {
      content: "''",
      borderLeft: '2px solid #2295F2',
      borderRight: '2px solid #E81E63',
      borderTop: '2px solid transparent',
      borderBottom: '2px solid transparent',
      borderRadius: '40px',
      width: '100%',
      height: '100%',
      display: 'block',
      position: 'absolute',
      boxSizing: 'border-box',
      transition: 'transform .3s linear .3s',
    },
  },
  loadingIndicatorElectron: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '40px',
    transition: 'transform 0.6s linear',
    '&:before': {
      content: "''",
      border: '1px solid transparent',
      borderRadius: '15px',
      width: '6px',
      height: '6px',
      display: 'block',
      position: 'absolute',
      transform: 'translateY(-50%)',
    },
  },
  loadingIndicatorX: {
    '&:before': {
      left: '10%',
      top: '15%',
      background: '#2295F2',
      boxShadow: '0px 0px 8px 1px #2295F2',
    },
  },
  loadingIndicatorY: {
    '&:before': {
      left: '75%',
      top: '84%',
      background: '#E81E63',
      boxShadow: '0px 0px 8px 1px #E81E63',
    },
  },
});

export default loadingIndicatorStyle;
