import { handleActions } from 'redux-actions';
import produce from 'immer';
import { loadProfile } from '../App/actions';
import { getLibrary } from './actions';
import {
  handleFailure,
  loadingEnd,
  loadingStart,
} from '../../utils/immerHelpers';

const initialState = {
  libraryItems: [],
  /**
   * Utils
   */
  loading: false,
  error: '',
};

const libraryReducer = handleActions(
  {
    [loadProfile.REQUEST]: loadingStart,
    [loadProfile.SUCCESS]: loadingEnd,
    [loadProfile.FAILURE]: loadingEnd,
    [getLibrary.REQUEST]: loadingStart,
    [getLibrary.SUCCESS]: produce((draft, { payload: { libraryItems } }) => {
      draft.loading = false;
      draft.libraryItems = libraryItems;
    }),
    [getLibrary.FAILURE]: handleFailure,
  },
  initialState,
);

export default libraryReducer;
