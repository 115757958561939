import { call, put, takeLatest } from 'redux-saga/effects';
import { getBlueJeansData } from '../actions';
import Api from '../../../utils/Api';

function* watchGetBlueJeansData() {
  yield takeLatest(getBlueJeansData.REQUEST, requestGetBlueJeansData);
}

function* requestGetBlueJeansData() {
  try {
    const { data } = yield call(Api.blueJeans.account.get);

    console.log('requestGetBlueJeansData response: ', data);

    yield put(getBlueJeansData.success(data));
  } catch (error) {
    yield put(getBlueJeansData.failure(error.response.data));
  }
}

export default watchGetBlueJeansData;
