import { call, put, takeLatest } from 'redux-saga/effects';
import { updateBlueJeansAccount } from '../actions';
import Api from '../../../utils/Api';

function* watchUpdateBlueJeansAccount() {
  yield takeLatest(
    updateBlueJeansAccount.REQUEST,
    requestUpdateBlueJeansAccount,
  );
}

function* requestUpdateBlueJeansAccount({ payload: { values, resolve } }) {
  try {
    const { data } = yield call(Api.blueJeans.account.put, values);

    console.log('updateBlueJeansAccount response: ', data);

    resolve();

    yield put(updateBlueJeansAccount.success(data));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateBlueJeansAccount.failure(error.response.data));
  }
}

export default watchUpdateBlueJeansAccount;
