import { createRoutine } from 'redux-saga-routines';

const abelanaAuthorize = createRoutine('LOGIN/ABELANA_AUTHORIZE', {
  request: (values, resolve) => ({ values, resolve }),
  success: (token) => ({ token }),
  failure: (error) => ({ error }),
});

const k12Authorize = createRoutine('LOGIN/K12_AUTHORIZE', {
  request: (email, educatorCode, applicationId, resolve) => ({
    email,
    educatorCode,
    applicationId,
    resolve,
  }),
  success: (token) => ({ token }),
  failure: (error) => ({ error }),
});

const signup = createRoutine('LOGIN/SIGN_UP', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
  failure: (error) => ({ error }),
});

const logout = createRoutine('LOGIN/LOGOUT', {
  request: (username, password) => ({ username, password }),
  success: (token) => ({ token }),
  failure: (error) => ({ error }),
});

const checkAppId = createRoutine('LOGIN/CHECK_APP_ID', {
  success: (appId) => ({ appId }),
});

const setWarning = createRoutine('LOGIN/SET_WARNING', {
  success: (warning) => ({ warning }),
});

const clearAppId = createRoutine('LOGIN/CLEAR_APP_ID');

const getApps = createRoutine('LOGIN/GET_APPS', {
  success: (apps) => ({ apps }),
  failure: (error) => ({ error }),
});

const checkResetToken = createRoutine('LOGIN/CHECK_RESET_TOKEN', {
  success: (token) => ({
    token,
  }),
});

const resetCheck = createRoutine('LOGIN/RESET_CHECK', {
  request: (username, resolve) => ({ username, resolve }),
  failure: (error) => ({ error }),
});

const resetPassword = createRoutine('LOGIN/RESET_PASSWORD', {
  request: (newPassword, newPassword1, resolve) => ({
    newPassword,
    newPassword1,
    resolve,
  }),
  failure: (error) => ({ error }),
});

const clearToken = createRoutine('LOGIN/CLEAR_TOKEN');

export {
  abelanaAuthorize,
  k12Authorize,
  logout,
  checkAppId,
  getApps,
  signup,
  clearAppId,
  setWarning,
  checkResetToken,
  resetCheck,
  resetPassword,
  clearToken,
};
