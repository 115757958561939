import { call, put, takeLatest } from 'redux-saga/effects';
import { addK12Application } from '../actions';
import Api from '../../../utils/Api';

function* watchAddK12Application() {
  yield takeLatest(addK12Application.REQUEST, requestAddK12Application);
}

function* requestAddK12Application({
  payload: { values, resolve, accountID },
}) {
  try {
    const { data } = yield call(Api.k12.application.post, {
      ...values,
      vil_account_id: accountID,
    });

    console.log('requestAddK12Application response: ', data);

    resolve();

    yield put(addK12Application.success(data, accountID));
  } catch (error) {
    resolve(error.response.data);
    yield put(addK12Application.failure(error.response.data));
  }
}

export default watchAddK12Application;
