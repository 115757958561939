const sidebarStyle = (theme) => ({
  toolbar: {
    backgroundColor: '#272B34',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '65px',
    padding: theme.spacing(1),
  },
  linkMini: {},
  logo: {
    display: 'block',
    height: '100%',
  },
  logoMini: {},
  title: {
    margin: theme.spacing(0.5, 0),
  },
  drawer: {
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#FAFAFA',
  },
  drawerOpen: {
    width: theme.variables.sidebarWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: theme.variables.sidebarMiniWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  menuLink: {
    display: 'block',
  },
  listItemSelected: {
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: theme.spacing(0.5),
      height: '100%',
      background: theme.palette.primary.main,
    },
  },
});

export default sidebarStyle;
