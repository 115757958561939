import React, { useRef } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Slide from '../Slide';
import abelana from '../../../../../../../images/login/abelana.png';
import visceralScienceEvolutionOfStars from '../../../../../../../images/login/visceral_science_evolution_of_stars.png';
import virtualClassroomAtomMaker from '../../../../../../../images/login/virtual_classroom_atom_maker.png';
import visceralScienceGravitySimulator from '../../../../../../../images/login/visceral_science_gravity_simulator.png';
import visceralScienceNearTheSpeedOfLight from '../../../../../../../images/login/visceral_science_near_the_speed_of_light.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowIcon from '../../../../../../../images/login/arrow_icon.svg';
import contentSliderStyle from './styles/contentSliderStyle';

const ContentSlider = ({ classes }) => {
  const slides = [
    {
      title: 'ABELANA',
      description:
        'ABELANA is a platform for hyper-lessons that use virtual and augmented reality to bring education to a new level. Our key online experiences can be used both in-class and remotely, as well as within specially equipped facilities. Immersive environments allow teachers to increase effectiveness of the learning process by visualizing concepts in a more intuitive form that makes comprehension easier, faster, and less stressful, while the memory of a material lasts longer. The online component of individual experiences further enhances them through competitive and collaborative games.',
      image: abelana,
    },
    {
      title: 'VISCERAL SCIENCE: EVOLUTION OF STARS',
      description:
        'This experience has been designed to use virtual reality to capture students’ interest in physics, and more specifically, the formation of star systems. In the process, students will be introduced to nuanced concepts involving force and motion, energy conservation, as well as get a better understanding of the lifecycle of stars that they can build upon in advanced physics classes.',
      image: visceralScienceEvolutionOfStars,
    },
    {
      title: 'VIRTUAL CLASSROOM: ATOM MAKER',
      description:
        'This is a hyper-lesson experience that allows teachers to conduct a class fully in virtual reality, including remotely. Focused on the periodic table, the lesson helps students to assemble elements starting from the nucleus and then go further through all energy levels of an atom. The experience provides a more intuitive understanding of atomic structures and shapes of orbitals, as well as enhances the process through a series of competitive mini-games that make learning more fun and impactful.',
      image: virtualClassroomAtomMaker,
    },
    {
      title: 'VISCERAL SCIENCE: GRAVITY SIMULATOR',
      description:
        'This VR experience shows how various celestial bodies interact with each other under the force of gravity. Students will be able to work in a team to assemble unique scenarios that involve stars, planets, moons, black holes, and other objects, experience gravitational waves, simulate collisions, and observe their results. One of the unique features of the experience in the demonstration of gravitational effects in 3D.',
      image: visceralScienceGravitySimulator,
    },
    {
      title: 'VISCERAL SCIENCE: NEAR THE SPEED OF LIGHT',
      description:
        'A crossplay that pitches a team of mobile players against opponents in VR and introduces them to a bizarre relativistic world of what happens when you fly closer to the speed of light. Students experience changes that happen due to the Relativistic Doppler Effect, Headlight Effect, Lorentz Contraction and Time Asynchronisation. Ideal for classrooms with multiple mobile devices and a limited number of VR headsets.',
      image: visceralScienceNearTheSpeedOfLight,
    },
  ];

  const SliderWrap = styled.div`
    .slick-slider {
      .slick-list {
        overflow: visible;
      }
    }
  `;

  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    adaptiveHeight: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickRef = useRef(null);

  const handleNextSlide = () => {
    slickRef.current.slickNext();
  };

  const handlePrevSlide = () => {
    slickRef.current.slickPrev();
  };

  return (
    <div className={classes.wrapper}>
      <SliderWrap>
        <Slider {...settings} ref={slickRef}>
          {slides.map((slide, index) => (
            <Slide
              {...slides[index]}
              slidesCount={slides.length}
              index={index}
              key={slide.title}
            />
          ))}
        </Slider>
        <div className={classes.toolbar}>
          <div className={classes.progressBar} />
          <Button className={classes.buttonLeft}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
              onClick={handlePrevSlide}
              src={ArrowIcon}
              className={classes.leftArrowIcon}
              alt="Left"
            />
          </Button>
          <Button className={classes.buttonRight}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
              onClick={handleNextSlide}
              src={ArrowIcon}
              className={classes.rightArrowIcon}
              alt="Right"
            />
          </Button>
        </div>
      </SliderWrap>
    </div>
  );
};

ContentSlider.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(contentSliderStyle)(ContentSlider);
