import { call, put, select, takeLatest } from 'redux-saga/effects';
import { updateUsername } from '../actions';
import Api from '../../../utils/Api';
import { makeSelectUserProfileRequired } from '../../App/selectors';

function* watchUpdateUsername() {
  yield takeLatest(updateUsername.REQUEST, requestUpdateUsername);
}

function* requestUpdateUsername({ payload: { values, resolve } }) {
  try {
    const userProfileRequired = yield select(makeSelectUserProfileRequired());

    const { data } = yield call(Api.auth.userProfile, {
      ...userProfileRequired,
      ...values,
    });

    resolve();

    yield put(updateUsername.success(data));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateUsername.failure(error.response.data));
  }
}

export default watchUpdateUsername;
