import { all, fork } from 'redux-saga/effects';
import watchLoadProfile from './sagaWatchers/loadProfile';
import watchLogin from '../Login/saga';
import watchAccount from '../Account/saga';
import watchClearCatalogItem from '../CatalogItem/sagaWatchers/clearCatalogItem';
import watchClearLibraryItem from '../LibraryItem/sagaWatchers/clearLibraryItem';

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchAccount),
    fork(watchLoadProfile),
    fork(watchClearCatalogItem),
    fork(watchClearLibraryItem),
  ]);
}
