/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import globalReducer from './containers/App/reducer';
import accountReducer from './containers/Account/reducer';
import billingReducer from './containers/Billing/reducer';
import catalogReducer from './containers/Catalog/reducer';
import catalogItemReducer from './containers/CatalogItem/reducer';
import libraryReducer from './containers/Library/reducer';
import libraryItemReducer from './containers/LibraryItem/reducer';
import loginReducer from './containers/Login/reducer';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import homeReducer from './containers/Home/reducer';

const createReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    account: accountReducer,
    home: homeReducer,
    billing: billingReducer,
    catalog: catalogReducer,
    catalogItem: catalogItemReducer,
    library: libraryReducer,
    libraryItem: libraryItemReducer,
    login: loginReducer,
    global: globalReducer,
    language: languageProviderReducer,
    toastr: toastrReducer,
  });

export default createReducer;
