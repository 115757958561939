import { call, put, takeLatest } from 'redux-saga/effects';
import { getK12Data } from '../actions';
import Api from '../../../utils/Api';

function* watchGetK12Data() {
  yield takeLatest(getK12Data.REQUEST, requestGetK12Data);
}

function* requestGetK12Data() {
  try {
    const { data } = yield call(Api.k12.account.getAll);

    console.log('requestGetK12Data response: ', data);

    yield put(getK12Data.success(data));
  } catch (error) {
    yield put(getK12Data.failure(error.response.data));
  }
}

export default watchGetK12Data;
