const footerStyle = (theme) => ({
  footer: {
    padding: theme.spacing(2, 9),
    fontSize: 16,
  },
  white: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    background: 'rgba(256, 256, 256, 1)',
    color: 'rgba(0,0,0,0.38)',
  },
  black: {
    borderTop: '1px solid rgba(256, 256, 256, 0.12)',
    background: 'rgba(13, 17, 16, 0.3)',
    color: 'rgba(256, 256, 256, 0.6)',
  },
  transparent: {
    background: 'none',
    color: 'rgba(256, 256, 256, 0.6)',
  },
  textSecondary: {
    color: '#FAB001',
  },
});
export default footerStyle;
