import axios from 'axios';
import { APP_TOKEN } from '../constants';

const convertNullToUndefined = (object) => {
  if (object === null) {
    return undefined;
  }

  if (typeof object === 'object') {
    for (const key in object) {
      object[key] = convertNullToUndefined(object[key]);
    }
  }

  return object;
};

/* eslint-disable no-console */
const requestAxios = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

requestAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(APP_TOKEN);

    if (!token) {
      return config;
    }

    return {
      ...config,
      data: convertNullToUndefined(config.data),
      headers: {
        ...(config.headers || {}),
        Authorization: `Token ${token}`,
      },
    };
  },
  (error) => Promise.reject(error),
);

requestAxios.interceptors.response.use(
  (response) =>
    // console.log(response);
    response,
  // eslint-disable-next-line consistent-return
  (error) => {
    // const refreshToken = localStorage.getItem(APP_TOKEN);

    // console.log(error.response);
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    // return Promise.reject(error);

    localStorage.clear();
    window.location.reload(); // TODO: Create nice way to handle expired tokens
  },
);

const formDataAxios = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

formDataAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(APP_TOKEN);
    if (!token) {
      return config;
    }
    if (config.data) {
      const formData = new FormData();
      Object.keys(config.data).map((key) =>
        formData.append(key, config.data[key]),
      );
      return {
        ...config,
        data: formData,
        headers: {
          ...(config.headers || {}),
          Authorization: `Token ${token}`,
        },
      };
    }
    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: `Token ${token}`,
      },
    };
  },
  (error) => Promise.reject(error),
);

export default class AjaxQuery {
  static get(url, options = {}) {
    return requestAxios.get(url, { ...options });
  }

  static post(url, options = {}) {
    return requestAxios.post(url, { ...options });
  }

  static patch(url, options = {}) {
    return requestAxios.patch(url, { ...options });
  }

  static put(url, options = {}) {
    return requestAxios.put(url, { ...options });
  }

  static delete(url, options = {}) {
    return requestAxios.delete(url, { ...options });
  }

  static options(url, options = {}) {
    return requestAxios.options(url, { ...options });
  }

  static formData = {
    post(url, options = {}) {
      return formDataAxios.post(url, { ...options });
    },
    patch(url, options = {}) {
      return formDataAxios.patch(url, { ...options });
    },
  };
}
/* eslint-enable no-console */
