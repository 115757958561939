export const accountTabs = [
  {
    breadcrumbsTitle: 'Personal Info',
    path: '/account/general',
  },
  {
    breadcrumbsTitle: 'Payment Details',
    path: '/account/payment',
  },
  {
    breadcrumbsTitle: 'Security',
    path: '/account/security',
  },
  {
    breadcrumbsTitle: 'Social Links',
    path: '/account/social',
  },
  {
    breadcrumbsTitle: 'Linked Accounts',
    path: '/account/linked',
  },
];
