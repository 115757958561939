import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  addAccessKeys,
  clearSubscription,
  getLibraryItem,
  getSubscriptionBuy,
  getSubscriptionRenewal,
  updateAccessKey,
  updateIsAutoRenewal,
  clearLibraryItem,
  updateSubscriptionBuy,
  updateSubscriptionRenewal,
} from './actions';
import { loadProfile } from '../App/actions';
import {
  handleFailure,
  loadingEnd,
  loadingStart,
} from '../../utils/immerHelpers';

const initialState = {
  application: {
    access_keys: [],
    application_type: null,
    available_on_htc_vive: null,
    available_on_oculus: null,
    available_on_wmr: null,
    available_on_htc_valve_index: null,
    description: null,
    files: [],
    id: null,
    is_demo: null,
    logo: null,
    price: null,
    publication_date: null,
    publisher: {
      title: null,
      logo: null,
    },
    releases: null,
    right_side_image2_experience: null,
    right_side_image2_experience_alt_text: null,
    right_side_image_experience: null,
    right_side_image_experience_alt_text: null,
    right_side_text_experience: null,
    short_title: null,
    subscription: {
      amount: null,
      currency: null,
    },
    support_page: null,
    title: null,
    video: null,
  },
  created_at: null,
  id: null,
  is_active: null,
  user: {
    contract: {
      calculation_method: null,
      currency: null,
      experience_price: null,
      id: null,
      is_credit_card_required: null,
      per_hour: null,
      per_minute: null,
    },
    email: null,
    is_card_added: null,
    payment_card: null,
    phone_number: null,
    updated_at: null,
    vr_center_title: null,
  },
  /**
   * Subscription renewal
   */
  subscription: {
    countOfKeys: 3,
    keysWithAmount: null,
    keysWithMaximumRenewalPeriod: null,
    amountPerKey: null,
    totalAmount: null,
    currency: null,
  },
  /**
   * Utils
   */
  loading: false,
  error: null,
};

const libraryItemReducer = handleActions(
  {
    [loadProfile.REQUEST]: loadingStart,
    [loadProfile.SUCCESS]: loadingEnd,
    [loadProfile.FAILURE]: loadingEnd,
    [getLibraryItem.REQUEST]: loadingStart,
    [getLibraryItem.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;

      /**
       * Library Item
       */
      draft.application.access_keys = data.application.access_keys;
      draft.application.application_type = data.application.application_type;
      draft.application.available_on_htc_vive =
        data.application.available_on_htc_vive;
      draft.application.available_on_oculus =
        data.application.available_on_oculus;
      draft.application.available_on_wmr = data.application.available_on_wmr;
      draft.application.available_on_htc_valve_index =
        data.application.available_on_htc_valve_index;
      draft.application.description = data.application.description;
      draft.application.files = data.application.files;
      draft.application.id = data.application.id;
      draft.application.is_demo = data.application.is_demo;
      draft.application.logo = data.application.logo;
      draft.application.price = data.application.price;
      draft.application.publication_date = data.application.publication_date;
      draft.application.publisher = data.application.publisher;
      draft.application.releases = data.application.releases;
      draft.application.right_side_image2_experience =
        data.application.right_side_image2_experience;
      draft.application.right_side_image2_experience_alt_text =
        data.application.right_side_image2_experience_alt_text;
      draft.application.right_side_image_experience =
        data.application.right_side_image_experience;
      draft.application.right_side_image_experience_alt_text =
        data.application.right_side_image_experience_alt_text;
      draft.application.right_side_text_experience =
        data.application.right_side_text_experience;
      draft.application.short_title = data.application.short_title;
      draft.application.subscription = data.application.subscription;
      draft.application.support_page = data.application.support_page;
      draft.application.title = data.application.title;
      draft.application.video = data.application.video;

      /**
       * User
       */
      draft.user.contract = data.user.contract;
      draft.user.email = data.user.email;
      draft.user.is_card_added = data.user.is_card_added;
      draft.user.payment_card = data.user.payment_card;
      draft.user.phone_number = data.user.phone_number;
      draft.user.updated_at = data.user.updated_at;
      draft.user.vr_center_title = data.user.vr_center_title;

      /**
       * Other
       */
      draft.created_at = data.created_at;
      draft.id = data.id;
      draft.is_active = data.is_active;
    }),
    [getLibraryItem.FAILURE]: handleFailure,
    [updateIsAutoRenewal.REQUEST]: loadingStart,
    [updateIsAutoRenewal.SUCCESS]: produce(
      (draft, { payload: { key, value } }) => {
        draft.loading = false;

        draft.application.access_keys = draft.application.access_keys.map(
          (accessKey) =>
            accessKey.id === key
              ? { ...accessKey, is_auto_renewal: value }
              : accessKey,
        );
      },
    ),
    [updateIsAutoRenewal.FAILURE]: handleFailure,
    [getSubscriptionRenewal.REQUEST]: loadingStart,
    [getSubscriptionRenewal.SUCCESS]: produce(
      (draft, { payload: { data } }) => {
        draft.loading = false;

        draft.subscription.countOfKeys = data.count_of_keys;
        draft.subscription.keysWithAmount = data.keys_with_amount;
        draft.subscription.keysWithMaximumRenewalPeriod =
          data.keys_with_maximum_renewal_period;
        draft.subscription.totalAmount = data.total_amount;
        draft.subscription.currency = data.currency;
      },
    ),
    [getSubscriptionRenewal.FAILURE]: handleFailure,
    [updateSubscriptionRenewal.REQUEST]: loadingStart,
    [updateSubscriptionRenewal.SUCCESS]: loadingEnd,
    [updateSubscriptionRenewal.FAILURE]: handleFailure,
    [getSubscriptionBuy.REQUEST]: loadingStart,
    [getSubscriptionBuy.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;

      draft.subscription.totalAmount = data.amount;
      draft.subscription.amountPerKey = data.amount_per_key;
      draft.subscription.currency = data.currency;
    }),
    [getSubscriptionBuy.FAILURE]: handleFailure,
    [updateSubscriptionBuy.REQUEST]: loadingStart,
    [updateSubscriptionBuy.SUCCESS]: loadingEnd,
    [updateSubscriptionBuy.FAILURE]: handleFailure,
    [clearSubscription.SUCCESS]: produce((draft) => {
      draft.subscription = initialState.subscription;
    }),
    [updateAccessKey.REQUEST]: loadingStart,
    [updateAccessKey.SUCCESS]: produce((draft, { payload: { accessKeys } }) => {
      draft.loading = false;

      draft.application.access_keys = accessKeys;
    }),
    [updateAccessKey.FAILURE]: handleFailure,
    [addAccessKeys.REQUEST]: loadingStart,
    [addAccessKeys.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;

      draft.application.access_keys = [
        ...draft.application.access_keys,
        ...data,
      ];
    }),
    [addAccessKeys.FAILURE]: handleFailure,
    [clearLibraryItem.SUCCESS]: produce(() => initialState),
  },
  initialState,
);

export default libraryItemReducer;
