import { put, take } from 'redux-saga/effects';
import { clearLibraryItem } from '../actions';

function* watchClearLibraryItem() {
  while (true) {
    yield take(clearLibraryItem.REQUEST);

    yield put(clearLibraryItem.success());
  }
}

export default watchClearLibraryItem;
