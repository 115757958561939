import { call, put, takeLatest } from 'redux-saga/effects';
import { updateK12Application } from '../actions';
import Api from '../../../utils/Api';

function* watchUpdateK12Application() {
  yield takeLatest(updateK12Application.REQUEST, requestUpdateK12Application);
}

function* requestUpdateK12Application({
  payload: { values, resolve, accountID },
}) {
  try {
    const { data } = yield call(Api.k12.application.put, values.id, values);

    console.log('requestUpdateK12Application response: ', data);

    resolve();

    yield put(updateK12Application.success(data, accountID));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateK12Application.failure(error.response.data));
  }
}

export default watchUpdateK12Application;
