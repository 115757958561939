import { createRoutine } from 'redux-saga-routines';

const getCatalogItem = createRoutine('CATALOG_ITEM/GET_CATALOG_ITEM', {
  request: (catalogItemID) => ({ catalogItemID }),
  success: (catalogItem) => ({ catalogItem }),
  failure: (error) => ({ error }),
});

const addExperience = createRoutine('CATALOG_ITEM/ADD_EXPERIENCE', {
  request: (formValues) => ({ formValues }),
  success: (data) => ({ data }),
  failure: (error) => ({ error }),
});

const updateAccountPostAddExperience = createRoutine(
  'CATALOG_ITEM/UPDATE_ACCOUNT_POST_ADD_EXPERIENCE',
  {
    request: (
      first_name,
      last_name,
      phone_number,
      vr_center_title,
      website,
    ) => ({
      first_name,
      last_name,
      phone_number,
      vr_center_title,
      website,
    }),
    success: (
      first_name,
      last_name,
      phone_number,
      vr_center_title,
      website,
    ) => ({
      first_name,
      last_name,
      phone_number,
      vr_center_title,
      website,
    }),
    failure: (error) => ({ error }),
  },
);

const clearCatalogItem = createRoutine('CATALOG_ITEM/CLEAR_CATALOG_ITEM');

export {
  getCatalogItem,
  addExperience,
  updateAccountPostAddExperience,
  clearCatalogItem,
};
