export const LOADING_START = 'app/LOADING/START';
export const LOADING_END = 'app/LOADING/END';
export const PROFILE_SET = 'app/Profile/PROFILE_SET';
export const PROFILE_UNSET = 'app/Profile/PROFILE_UNSET';
export const APP_TOKEN = 'app-token';

export const APP_API_ENDPOINT =
  process.env.NODE_ENV === 'production' ? '/api/v2' : '/proxy/api/v2';

export const CONTRACT_TYPES = {
  GENERIC: 0,
  CONTRACT1: 1,
  CONTRACT2: 2,
  CONTRACT4_CAD_30: 4,
};

export const APPLICATION_TYPES = {
  general: 1,
  demo: 4,
  free: 8,
  free2_with_keys: 9,
  launcher: 64,
  devtool: 65,
  private: 66,
  1: 'general',
  4: 'demo',
  8: 'free',
  9: 'free2_with_keys',
  64: 'launcher',
  65: 'devtool',
  66: 'private',
  FREE_APPS: [8, 9, 66],
};

export const EXPERIENCE_MAX_KEY_COUNT = 30;

// Local
// export const STRIPE_PUBLIC_KEY = '';
// export const RAVEN_DSN = '';

// Stage
export const STRIPE_PUBLIC_KEY = 'pk_test_Bi5rlLb7uENH3EDl70POQRZb';
export const RAVEN_DSN =
  'https://d3f676881a7d4fdd84aad04017efd002@sentry.abelanavr.com/2';

// Prod
// export const STRIPE_PUBLIC_KEY = 'pk_live_eYChupaZwFHfwpCoJGKwu1uh';
// export const RAVEN_DSN = 'https://544c0afb68144144ae52e076be7407fb@sentry.abelanavr.com/4';

export const BIZ_CONTRACT1_PRICE_TITLES = {
  [APPLICATION_TYPES.general]: 'Commercial Use',
  [APPLICATION_TYPES.demo]: 'Non-Commercial Use',
  [APPLICATION_TYPES.free]: 'Free',
  [APPLICATION_TYPES.free2_with_keys]: 'Free',
  [APPLICATION_TYPES.launcher]: 'Application launcher',
  [APPLICATION_TYPES.devtool]: 'Application developer tool',
  [APPLICATION_TYPES.private]: 'Private',
};

export const PRIVACY_POLICY_URL = 'https://abelanavr.com/privacy-policy';
export const MDM_LANDING_URL = 'https://mdm.abelanavr.com/';

export const PRIVO = {
  COPPA: {
    img: 'https://static.abelanavr.com/privo/img/COPPA.png',
    alt: 'COPPA Safe Harbor Certification by PRIVO',
  },
  STUDENT: {
    img: 'https://static.abelanavr.com/privo/img/STUDENT.png',
    alt: 'Student Digital Privacy Assured Program by PRIVO',
  },
};
