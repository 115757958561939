import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import { push } from 'connected-react-router';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {
  makeSelectLoading,
  makeSelectUserIsAuthenticated,
  makeSelectUserIsNotAuthenticated,
} from '../containers/App/selectors';

const locationHelper = locationHelperBuilder({});

export const userIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/login',
  authenticatedSelector: makeSelectUserIsAuthenticated(),
  authenticatingSelector: makeSelectLoading(),
  wrapperDisplayName: 'UserIsAuthenticated',
  redirectAction: push,
});

export const userIsNotAuthenticated = connectedReduxRedirect({
  redirectPath: (state, ownProps) => {
    const paramPattern = '?app_id=';
    if (ownProps.location.search.startsWith(paramPattern)) {
      const nextParamPos = ownProps.location.search.indexOf('&');
      const sliceEndBound =
        nextParamPos !== -1 ? nextParamPos : ownProps.location.search.length;
      return `/catalog/${ownProps.location.search.slice(
        paramPattern.length,
        sliceEndBound,
      )}`;
    }

    return locationHelper.getRedirectQueryParam(ownProps) || '/';
  },
  authenticatedSelector: makeSelectUserIsNotAuthenticated(),
  authenticatingSelector: makeSelectLoading(),
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  redirectAction: push,
});
