import { call, put, select, takeLatest } from 'redux-saga/effects';
import { resetPassword } from '../actions';
import { makeSelectToken } from '../selectors';
import Api from '../../../utils/Api';

/* eslint-disable no-console */
function* watchResetPassword() {
  yield takeLatest(resetPassword.REQUEST, requestSendPassword);
}

function* requestSendPassword({
  payload: { newPassword, newPassword1, resolve },
}) {
  try {
    const token = yield select(makeSelectToken());

    const { data } = yield call(Api.auth.reset.confirm, {
      new_password: newPassword,
      new_password1: newPassword1,
      token,
    });

    resolve();
    console.log('requestSendPassword response: ', data);
    yield put(resetPassword.success());
  } catch (error) {
    resolve(error.response.data);
    yield put(resetPassword.failure(error.response.data));
  }
}
/* eslint-enable no-console */

export default watchResetPassword;
