const dashboardWrapperStyle = (theme) => ({
  wrapper: {
    height: '100%',
  },
  mainPanel: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginLeft: 'auto',
  },
  mainPanelOpen: {
    width: `calc(100% - ${theme.variables.sidebarWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mainPanelClose: {
    width: `calc(100% - ${theme.variables.sidebarMiniWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  content: {
    backgroundColor: '#E6E6E6',
    flexGrow: 1,
    padding: theme.spacing(3, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
});

export default dashboardWrapperStyle;
