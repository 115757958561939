import { put, select, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
import { checkResetToken, clearToken } from '../actions';
import { makeSelectSearch } from '../../App/selectors';

function* watchCheckResetToken() {
  yield takeLatest(checkResetToken.REQUEST, requestCheckResetToken);
}

function* requestCheckResetToken() {
  yield put(clearToken.request());

  const { code: token } = queryString.parse(yield select(makeSelectSearch()));

  if (token) {
    yield put(checkResetToken.success(token));
  } else {
    yield put(checkResetToken.failure());
  }
}

export default watchCheckResetToken;
