import { put, take } from 'redux-saga/effects';
import { clearCatalogItem } from '../actions';

function* watchClearCatalogItem() {
  while (true) {
    yield take(clearCatalogItem.REQUEST);

    yield put(clearCatalogItem.success());
  }
}

export default watchClearCatalogItem;
