import { call, put, takeLatest } from 'redux-saga/effects';
import { refreshK12Application } from '../actions';
import Api from '../../../utils/Api';

function* watchRefreshK12Application() {
  yield takeLatest(refreshK12Application.REQUEST, requestRefreshK12Application);
}

function* requestRefreshK12Application({
  payload: { applicationID, accountID },
}) {
  try {
    const { data } = yield call(Api.k12.application.refresh, applicationID);

    console.log('requestRefreshK12Application response: ', data);

    yield put(refreshK12Application.success(data, accountID));
  } catch (error) {
    yield put(refreshK12Application.failure(error.response.data));
  }
}

export default watchRefreshK12Application;
