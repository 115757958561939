import { createRoutine } from 'redux-saga-routines';

const getLibraryItem = createRoutine('LIBRARY_ITEM/GET_LIBRARY_ITEM', {
  request: (libraryItemID) => ({ libraryItemID }),
  success: (data) => ({ data }),
  failure: (error) => ({ error }),
});

const getSubscriptionRenewal = createRoutine(
  'LIBRARY_ITEM/GET_SUBSCRIPTION_RENEWAL',
  {
    request: (keys) => ({ keys }),
    success: (data) => ({ data }),
    failure: (error) => ({ error }),
  },
);

const updateSubscriptionRenewal = createRoutine(
  'LIBRARY_ITEM/UPDATE_SUBSCRIPTION_RENEWAL',
  {
    request: (keys, resolve) => ({ keys, resolve }),
    failure: (error) => ({ error }),
  },
);

const getSubscriptionBuy = createRoutine('LIBRARY_ITEM/GET_SUBSCRIPTION_BUY', {
  request: (count, resolve) => ({ count, resolve }),
  success: (data) => ({ data }),
  failure: (error) => ({ error }),
});

const updateSubscriptionBuy = createRoutine(
  'LIBRARY_ITEM/UPDATE_SUBSCRIPTION_BUY',
  {
    request: (values, resolve) => ({ values, resolve }),
    failure: (error) => ({ error }),
  },
);

const clearSubscription = createRoutine('LIBRARY_ITEM/CLEAR_SUBSCRIPTION');

const updateIsAutoRenewal = createRoutine(
  'LIBRARY_ITEM/UPDATE_IS_AUTO_RENEWAL',
  {
    request: (key, enable, application) => ({ key, enable, application }),
    success: (key, value) => ({ key, value }),
    failure: (error) => ({ error }),
  },
);

const updateAccessKey = createRoutine('LIBRARY_ITEM/UPDATE_ACCESS_KEY', {
  request: (accessKey, resolve) => ({ accessKey, resolve }),
  success: (accessKeys) => ({ accessKeys }),
  failure: (error) => ({ error }),
});

const addAccessKeys = createRoutine('LIBRARY_ITEM/ADD_ACCESS_KEYS', {
  request: (values, resolve) => ({ values, resolve }),
  success: (data) => ({ data }),
  failure: (error) => ({ error }),
});

const clearLibraryItem = createRoutine('LIBRARY_ITEM/CLEAR_LIBRARY_ITEM');

export {
  getLibraryItem,
  updateIsAutoRenewal,
  updateAccessKey,
  addAccessKeys,
  clearLibraryItem,
  getSubscriptionRenewal,
  updateSubscriptionRenewal,
  getSubscriptionBuy,
  updateSubscriptionBuy,
  clearSubscription,
};
