const slideStyle = () => ({
  wrapper: {
    width: '455px',
    height: '280px',
    position: 'relative',
  },
  content: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  title: {
    position: 'relative',
    paddingBottom: '16px',
    fontWeight: '800',
    fontSize: '32px',
    lineHeight: '39px',
    textTransform: 'uppercase',
    color: '#FAB001',
    zIndex: '2',
  },
  description: {
    position: 'relative',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#FFFFFF',
    zIndex: '2',
  },
  imageWrapper: {
    width: '790px',
    position: 'absolute',
    top: '-350px',
    right: '-260px',
  },
  image: {
    width: '100%',
  },
  infoBar: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    bottom: '-72px',
    left: '0',
  },
  slideNumber: {
    width: '45px',
    marginRight: '8px',
    fontWeight: '800',
    lineHeight: '24px',
    fontSize: '32px',
    color: '#FFFFFF',
  },
});

export default slideStyle;
