import { call, put, select, takeLatest } from 'redux-saga/effects';
import { updateOnlinePresence } from '../actions';
import { makeSelectUserProfileRequired } from '../../App/selectors';
import Api from '../../../utils/Api';

function* watchUpdateOnlinePresence() {
  yield takeLatest(updateOnlinePresence.REQUEST, requestUpdateOnlinePresence);
}

function* requestUpdateOnlinePresence({ payload: { values, resolve } }) {
  try {
    const userProfileRequired = yield select(makeSelectUserProfileRequired());

    const { data } = yield call(Api.auth.userProfile, {
      ...userProfileRequired,
      ...values,
    });

    console.log('requestUpdateOnlinePresence response: ', data);

    resolve();

    yield put(updateOnlinePresence.success(data));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateOnlinePresence.failure(error.response.data));
  }
}

export default watchUpdateOnlinePresence;
