import produce from 'immer';

const loadingStart = produce((draft) => {
  draft.loading = true;
});

const loadingEnd = produce((draft) => {
  draft.loading = false;
});

const handleFailure = produce((draft, { payload: { error } }) => {
  draft.loading = false;
  draft.error = error;
});

export { loadingStart, loadingEnd, handleFailure };
