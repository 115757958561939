import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteK12Application } from '../actions';
import Api from '../../../utils/Api';

function* watchDeleteK12Application() {
  yield takeLatest(deleteK12Application.REQUEST, requestDeleteK12Application);
}

function* requestDeleteK12Application({
  payload: { applicationID, accountID },
}) {
  try {
    const { data } = yield call(Api.k12.application.delete, applicationID);

    console.log('requestDeleteK12Application response: ', data);

    yield put(deleteK12Application.success(applicationID, accountID));
  } catch (error) {
    yield put(deleteK12Application.failure(error.response.data));
  }
}

export default watchDeleteK12Application;
